/** @jsxImportSource @emotion/react */ // NOTE: 공용 컴포넌트에서 className으로 수정하려고 하면 필요
import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useTranslation } from "react-i18next";

import { Tooltip } from "@/components";
import type { ColumnTable, ColumnTooltip } from "@/types";

import * as S from "./Table.styled";

interface TableSkeletonProps {
  className?: string;
  gridTemplateColumns: string;
  columnTable: ColumnTable;
  columnTooltip?: ColumnTooltip;
  rowCount?: number;
}

const TableSkeleton = ({
  className,
  gridTemplateColumns,
  columnTable,
  columnTooltip,
  rowCount = 10,
}: TableSkeletonProps) => {
  const { t } = useTranslation();

  return (
    <S.Table className={className} gridTemplateColumns={gridTemplateColumns}>
      <thead>
        <S.HeadRow>
          {Object.entries(columnTable).map(([key, label], i) => (
            <th key={i} title={label}>
              {t(label)}
              {columnTooltip?.[key] && (
                <Tooltip
                  css={S.headerTooltip}
                  position={columnTooltip[key].position}
                  message={columnTooltip[key].message}
                />
              )}
            </th>
          ))}
        </S.HeadRow>
      </thead>
      <tbody>
        {[...Array(rowCount)].map((_, i) => (
          <S.Row key={i}>
            {Object.keys(columnTable).map((item) => (
              <S.Td key={item}>
                <Skeleton />
              </S.Td>
            ))}
          </S.Row>
        ))}
      </tbody>
    </S.Table>
  );
};

export default TableSkeleton;
