/** @jsxImportSource @emotion/react */ // NOTE: 공용 컴포넌트에서 className으로 수정하려고 하면 필요
import React from "react";

import { useGoogleMap } from "@/context";

import * as S from "./GoogleMap.styled";

interface GoogleMapProps {
  className?: string;
}

const GoogleMap = ({ className }: GoogleMapProps) => {
  const { ref } = useGoogleMap();

  return <S.GoogleMap ref={ref} className={className} />;
};

export default GoogleMap;
