import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import type { Languages } from "@/types";

import * as S from "./DataSection.styled";

interface DataSectionSkeletonProps {
  className?: string;
  children: React.ReactNode;
  buttonCount?: number;
  title?: Languages;
}

const DataSectionSkeleton = ({
  className,
  children,
  buttonCount = 0,
  title = "List",
}: DataSectionSkeletonProps) => {
  const { t } = useTranslation();

  return (
    <section className={className}>
      <S.Header>
        <S.LeftContent>
          <h2>{t(title)}</h2>
        </S.LeftContent>
        <S.ActiveWrapper>
          {[...Array(buttonCount)].map((_, i) => (
            <Skeleton key={i} width={130} height={40} />
          ))}
        </S.ActiveWrapper>
      </S.Header>
      {children}
    </section>
  );
};

export default DataSectionSkeleton;
