import React from "react";
import { useIsFetching } from "@tanstack/react-query";

import { Filter } from "@/components";
import { useFilterState } from "@/hooks";

import { deviceKeys } from "services";
import * as S from "./DeviceDetailFilter.styled";

const DeviceDetailFilter = () => {
  const fetchingQueryNumber = useIsFetching({ queryKey: deviceKeys.details() });

  const defaultValue = { date: [] };

  const searchLabels = [
    { queryKeyLabel: { date: "Date" }, options: { isRequired: true } },
  ] as const;

  const {
    filterLocalValue,
    filterLabels,
    handleLocalValueChange,
    handleLocalValueReset,
    handleTotalValueChange,
    handleTotalValueReset,
  } = useFilterState(defaultValue, searchLabels);

  return (
    <Filter
      isLoadingApplyButton={!!fetchingQueryNumber}
      filterLocalValue={filterLocalValue}
      filterLabels={filterLabels}
      handleLocalValueChange={handleLocalValueChange}
      handleTotalValueChange={handleTotalValueChange}
      handleLocalValueReset={handleLocalValueReset}
      handleTotalValueReset={handleTotalValueReset}
    >
      <Filter.Row>
        <Filter.Condition isRequired conditionName="Date">
          <Filter.Calendar calendarType="date" queryKey={{ date: "Date" }} />
        </Filter.Condition>
      </Filter.Row>
    </Filter>
  );
};

export default DeviceDetailFilter;
