import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { isEmpty } from "lodash";

import { isInitFilterAtom } from "@/stores";

import type { GetIoTDeviceDetailQueryModel } from "types";

const useDeviceDetailFilter = (req: GetIoTDeviceDetailQueryModel) => {
  const [isInitFilter, setIsInitFilter] = useRecoilState(isInitFilterAtom);

  const hasFilterQueries = !isEmpty(req.query.date);

  useEffect(() => {
    if (!hasFilterQueries || !isInitFilter) return;

    setIsInitFilter(false);
  }, [req]);
};

export default useDeviceDetailFilter;
