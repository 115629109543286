import { css } from "@emotion/react";

export const googleMap = css`
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000000;
`;
