import React from "react";
import { atom } from "recoil";

const isTimeStartAtom = atom({
  key: "isTimeStartAtom",
  default: false,
});

const isTimeOutAtom = atom({
  key: "isTimeOutAtom",
  default: false,
});

const isTimeResetAtom = atom({
  key: "isTimeResetAtom",
  default: false,
});

export { isTimeStartAtom, isTimeOutAtom, isTimeResetAtom };
