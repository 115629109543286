import React, { Suspense } from "react";

import { DataSectionSkeleton, TableSkeleton } from "@/components";

import { PageLayout } from "components";
import {
  DEVICE_DETAIL_LAYOUT,
  DEVICE_DETAIL_TABLE_GRID_COLUMNS,
  DEVICE_DETAIL_TABLE_HEADER,
} from "assets";
import { DeviceDetailFilter, DeviceDetailSection } from "./containers";

const DeviceDetail = () => {
  return (
    <PageLayout layoutStaticData={DEVICE_DETAIL_LAYOUT}>
      <DeviceDetailFilter />
      <Suspense
        fallback={
          <DataSectionSkeleton>
            <TableSkeleton
              gridTemplateColumns={DEVICE_DETAIL_TABLE_GRID_COLUMNS}
              columnTable={DEVICE_DETAIL_TABLE_HEADER}
            />
          </DataSectionSkeleton>
        }
      >
        <DeviceDetailSection />
      </Suspense>
    </PageLayout>
  );
};

export default DeviceDetail;
