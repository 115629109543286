import React, { createContext, useContext } from "react";
import { useTranslation } from "react-i18next";
import type { Namespace, TFunction } from "i18next";

import { checkPasswordLength, checkPasswordType } from "@/utils";
import { CheckIcon } from "@/assets";
import { COMMON_ERROR_MSG } from "@/constants";

import * as S from "./PasswordCondition.styled";

interface PasswordConditionProps {
  className?: string;
  children: React.ReactNode;
  currentPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
  touchedFields: {
    currentPassword?: boolean | undefined;
    newPassword?: boolean | undefined;
    confirmPassword?: boolean | undefined;
  };
}

interface PasswordConditionContextType
  extends Omit<PasswordConditionProps, "className" | "children"> {
  t: TFunction<Namespace<"en" | "lo">, undefined, Namespace<"en" | "lo">>;
}

const PasswordConditionContext = createContext<PasswordConditionContextType>({
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
  touchedFields: {},
  t: ((key: string) => key) as TFunction<
    Namespace<"en" | "lo">,
    undefined,
    Namespace<"en" | "lo">
  >,
});

const PasswordCondition = ({
  className,
  children,
  currentPassword,
  newPassword,
  confirmPassword,
  touchedFields,
}: PasswordConditionProps) => {
  const { t } = useTranslation();

  return (
    <PasswordConditionContext.Provider
      value={{
        currentPassword,
        newPassword,
        confirmPassword,
        touchedFields,
        t,
      }}
    >
      <S.PasswordConditionWrapper className={className}>
        {children}
      </S.PasswordConditionWrapper>
    </PasswordConditionContext.Provider>
  );
};

PasswordCondition.lengthCondition = function lengthCondition() {
  const { newPassword, touchedFields, t } = useContext(
    PasswordConditionContext,
  );

  const isLengthError =
    !touchedFields.newPassword && !newPassword
      ? undefined
      : !checkPasswordLength(newPassword!);

  return (
    <S.PasswordCondition hasError={isLengthError}>
      <CheckIcon />
      {t(COMMON_ERROR_MSG.PASSWORD_LENGTH)}
    </S.PasswordCondition>
  );
};

PasswordCondition.textTypeCondition = function textTypeCondition() {
  const { newPassword, touchedFields, t } = useContext(
    PasswordConditionContext,
  );

  const isTypeError =
    !touchedFields.newPassword && !newPassword
      ? undefined
      : !checkPasswordType(newPassword!);

  return (
    <S.PasswordCondition hasError={isTypeError}>
      <CheckIcon />
      {t(COMMON_ERROR_MSG.PASSWORD_TYPE)}
    </S.PasswordCondition>
  );
};

PasswordCondition.passwordCondition = function passwordCondition() {
  const { currentPassword, newPassword, touchedFields, t } = useContext(
    PasswordConditionContext,
  );

  const isSameError =
    !touchedFields.newPassword && !newPassword
      ? undefined
      : !newPassword
      ? true
      : currentPassword === newPassword;

  return (
    <S.PasswordCondition hasError={isSameError}>
      <CheckIcon />
      {t(COMMON_ERROR_MSG.CANNOT_UPDATE_PASSWORD)}
    </S.PasswordCondition>
  );
};

PasswordCondition.checkConfirmPassword = function checkConfirmPassword() {
  const { newPassword, confirmPassword, touchedFields, t } = useContext(
    PasswordConditionContext,
  );

  const hasConfirmError =
    !touchedFields.confirmPassword && !confirmPassword
      ? undefined
      : !confirmPassword || confirmPassword !== newPassword
      ? true
      : false;

  return (
    <S.PasswordCondition hasError={hasConfirmError}>
      <CheckIcon />
      {t(COMMON_ERROR_MSG.PASSWORD_CONFIRM)}
    </S.PasswordCondition>
  );
};

export default PasswordCondition;
