import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.main`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    padding-top: 100px;
    background-color: ${theme.color.gray_10};
  `}
`;
