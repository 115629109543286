import React, { useState, useEffect, createContext, useContext } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { IoTAuth } from "utils";
import type { LoginServerModel } from "types";

export const auth = IoTAuth.getInstance();

export const AuthContext = createContext<
  | {
      auth: IoTAuth;
      initializing: boolean;
      user: LoginServerModel | null;
      error: { message: string } | null;
    }
  | undefined
>(undefined);

AuthContext.displayName = "AuthContext";

export function useAuth() {
  const auth = useContext(AuthContext);

  if (!auth) {
    throw new Error("useAuth must be used within AuthProvider");
  }

  return auth;
}

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const queryClient = useQueryClient();

  const [initializing, setInitializing] = useState(true);
  const [error, setError] = useState<{ message: string } | null>(null);
  const [user, setUser] = useState<LoginServerModel | null>(null);

  const value = { initializing, auth, error, user };

  useEffect(() => {
    auth
      .resolveUser()
      .onAuthStateChanged((user: LoginServerModel | null, error) => {
        if (user) {
          setUser(user);
          setError(null);
        } else {
          setUser(null);
          queryClient.clear();
          if (error) {
            setError(error);
          }
        }
        setInitializing(false);
      });
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
