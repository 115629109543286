import { TokenService } from "@/utils";
import { AxiosService } from "@/apis";

import { auth } from "contexts";

const axiosService = new AxiosService(
  TokenService.getInstance({ serviceName: "iot", auth }),
);

export const ax = axiosService.setInterceptors();
