/** @jsxImportSource @emotion/react */ // NOTE: 공용 컴포넌트에서 className으로 수정하려고 하면 필요
import React, { FC, SVGProps } from "react";
import { NavLink } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

import { isInitFilterAtom } from "@/stores";
import type { Languages } from "@/types";

import * as S from "./Navbar.styled";

interface NavbarProps {
  navs: {
    path: string;
    Icon: FC<SVGProps<SVGSVGElement>>;
    content: Languages;
  }[];
  checkAccessDenied?: (path: string) => boolean;
}

const Navbar = ({ navs, checkAccessDenied }: NavbarProps) => {
  const { t } = useTranslation();

  const [isInitFilter, setIsInitFilter] = useRecoilState(isInitFilterAtom);

  const handleNavLinkClick = () => {
    if (isInitFilter) return;

    setIsInitFilter(true);
  };

  return (
    <S.Navbar>
      <ul>
        {navs.map(({ path, Icon, content }) => {
          const isAccessDenied = checkAccessDenied
            ? checkAccessDenied(path)
            : false;

          if (isAccessDenied) return null;

          return (
            <li key={path}>
              <NavLink
                css={S.link(t(content))}
                to={path}
                onClick={handleNavLinkClick}
              >
                <Icon />
              </NavLink>
            </li>
          );
        })}
      </ul>
    </S.Navbar>
  );
};

export default Navbar;
