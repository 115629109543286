import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const AccountIdForm = styled.form<{ isVerifyDone: boolean }>`
  ${({ theme, isVerifyDone }) => css`
    background-color: ${isVerifyDone && theme.color.gray_10};
  `}
`;

export const EmailVerifyButton = styled.button`
  ${({ theme }) => css`
    ${theme.button.outlined};
    position: absolute;
    right: 0px;
    bottom: 8px;
  `}
`;

export const HideSubmitButton = styled.button`
  display: none;
`;

export const verifyInput = css`
  padding-right: calc(80px + 16px);
`;

export const alertMsg = css`
  margin-top: 8px;
`;
