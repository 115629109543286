import React from "react";
import dayjs from "dayjs";

import type { MonthYear } from "@/types";

import SingleDate from "../../date/singleDate/SingleDate";

interface SingleDatesProps {
  datePicker: {
    monthYear: MonthYear;
    handlePrevMonthChange: () => void;
    handleNextMonthChange: () => void;
  };
  calendar: {
    currentDate: dayjs.Dayjs[] | [];
    handleDateClick: (date: dayjs.Dayjs[]) => () => void;
  };
}

const SingleDates = ({ datePicker, calendar }: SingleDatesProps) => {
  return (
    <>
      {[...Array(datePicker.monthYear.firstDOW)].map((_, i) => (
        <SingleDate
          key={i}
          disabled
          currentDate={calendar.currentDate}
          date={datePicker.monthYear.firstWeekPrevMonthDate.add(i, "d")}
          changeMonth={datePicker.handlePrevMonthChange}
          handleClick={calendar.handleDateClick}
        />
      ))}
      {[...Array(datePicker.monthYear.lastDate)].map((_, i) => {
        const date = datePicker.monthYear.startDate.add(i, "d");

        return (
          <SingleDate
            key={i}
            isThisMonth={true}
            currentDate={calendar.currentDate}
            date={date}
            handleClick={calendar.handleDateClick}
          />
        );
      })}
      {[
        ...Array(
          42 - (datePicker.monthYear.firstDOW + datePicker.monthYear.lastDate),
        ),
      ].map((_, i) => (
        <SingleDate
          key={i}
          disabled
          currentDate={calendar.currentDate}
          date={datePicker.monthYear.nextMonthStartDate.add(i, "d")}
          changeMonth={datePicker.handleNextMonthChange}
          handleClick={calendar.handleDateClick}
        />
      ))}
    </>
  );
};

export default SingleDates;
