import React, { useId } from "react";

import * as S from "./Checkbox.styled";

interface CheckboxProps {
  isDisabled?: boolean;
  isChecked?: boolean;
  handleCheck?: () => void;
}

const Checkbox = ({
  isChecked = false,
  isDisabled = false,
  handleCheck,
}: CheckboxProps) => {
  const uuid = useId();

  return (
    <S.Wrapper>
      <S.Input
        type="checkbox"
        readOnly
        disabled={isDisabled}
        checked={isChecked}
        id={uuid}
        onChange={handleCheck}
      />
      <S.CheckboxClickAbleArea className="label" htmlFor={uuid} tabIndex={0}>
        <S.CheckBox className="box" />
      </S.CheckboxClickAbleArea>
    </S.Wrapper>
  );
};

export default Checkbox;
