import type { Coord } from "@/types";

import { ax } from "apis";
import type {
  DeleteIoTDeviceQueryModel,
  GetIoTDeviceDetailQueryModel,
  GetIoTDeviceDetailServerModel,
  GetIoTDeviceQueryModel,
  GetIoTDeviceServerModel,
} from "types";

//LINK: https://www.notion.so/coconutsilo/iot-devices-62171499d35448d9ba735a29003779d1
export const getIoTDeviceAPI = async (req: GetIoTDeviceQueryModel) => {
  const { data } = await ax.get<GetIoTDeviceServerModel>("/iot/devices", {
    params: req.query,
  });

  return data;
};

//LINK: https://www.notion.so/coconutsilo/iot-devices-iotId-d92144551cf24489a3e9480fae358865
export const getIoTDeviceDetailAPI = async (
  req: GetIoTDeviceDetailQueryModel,
) => {
  const { data } = await ax.get<GetIoTDeviceDetailServerModel>(
    `/iot/devices/${req.id}`,
    { params: req.query },
  );

  return data;
};

export const getDeviceLocationAPI = async (iotId: string) => {
  const { data } = await ax.get<Coord>(`/iot/devices/${iotId}/location`);

  return data;
};

export const deleteIoTDeviceAPI = (req: DeleteIoTDeviceQueryModel) =>
  ax.delete(`/iot/devices/${req.body.iotId}`);
