import { useMutation } from "@tanstack/react-query";

import type { FileRequestType } from "@/types";

import { getFileAPI } from "apis";
import type { IotApiError } from "types";

// NOTE: common의 DownloadButton을 수정하지 않기 위해서 mutate로 설정
// NOTE: res 자체를 Blob으로 념겨주고 있어서 해당과 같이 타입 설정
export const useDownloadFile = () =>
  useMutation<Blob, IotApiError, FileRequestType>({
    mutationFn: (req: FileRequestType) => getFileAPI(req),
    onSuccess: (res, variables) => {
      const url = window.URL.createObjectURL(res);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${variables.fileName}.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    },
  });
