import { atom } from "recoil";

interface VerifyAccountInfo {
  email: string;
  authCode: string;
  isEmailVerifyDone: boolean;
}

export const initVerifyAccountInfo = {
  email: "",
  authCode: "",
  isEmailVerifyDone: false,
};

const verifyAccountInfoAtom = atom<VerifyAccountInfo>({
  key: "verifyAccountInfoAtom",
  default: initVerifyAccountInfo,
});

export { verifyAccountInfoAtom };
