/** @jsxImportSource @emotion/react */ // NOTE: 공용 컴포넌트에서 className으로 수정하려고 하면 필요
import React, { useState, createContext, useContext, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import type { UseFormRegisterReturn } from "react-hook-form";

import { EyeAbledIcon, EyeDisabledIcon } from "@/assets";
import type { Languages } from "@/types";

import * as S from "./AccountLabelInput.styled";

interface LabelInputContextType {
  id: string;
  focusedId: string;
  handleFocus: (id: string) => () => void;
}

interface AccountLabelInputProps {
  className?: string;
  children: ReactNode;
  id: string;
  label: Languages;
  value: string | undefined;
}

interface InputProps {
  className?: string;
  register: UseFormRegisterReturn<string>;
  hasErr: boolean;
  maxLength?: number;
  disabled?: boolean;
}

interface PasswordProps {
  register: UseFormRegisterReturn<string>;
  hasErr: boolean;
  disabled?: boolean;
}

const LabelInputContext = createContext<LabelInputContextType>({
  id: "",
  focusedId: "",
  handleFocus: () => () => {},
});

const AccountLabelInput = ({
  className,
  children,
  id,
  label,
  value,
}: AccountLabelInputProps) => {
  const { t } = useTranslation();

  const [focusedId, setFocusedId] = useState("");

  const handleFocus = (id: string) => (): void => {
    setFocusedId(id);
  };

  return (
    <LabelInputContext.Provider value={{ id, focusedId, handleFocus }}>
      <S.LabelInputWrapper className={className}>
        <S.Label htmlFor={id} isLabelTop={focusedId === id || !!value}>
          {t(label)}
        </S.Label>
        {children}
      </S.LabelInputWrapper>
    </LabelInputContext.Provider>
  );
};

AccountLabelInput.Input = function Input({
  className,
  disabled,
  hasErr,
  maxLength = 100,
  register,
}: InputProps) {
  const { id, handleFocus } = useContext(LabelInputContext);

  return (
    <S.Input
      className={className}
      hasErr={hasErr}
      disabled={disabled}
      id={id}
      maxLength={maxLength}
      onFocus={handleFocus(id)}
      {...register}
      onBlur={handleFocus("")}
    />
  );
};

AccountLabelInput.Password = function Password({
  disabled,
  hasErr,
  register,
}: PasswordProps) {
  const { id, handleFocus } = useContext(LabelInputContext);
  const [isShow, setIsShow] = useState(false);

  const handleClick = (): void => {
    setIsShow(!isShow);
  };

  return (
    <>
      <S.Input
        css={S.password}
        disabled={disabled}
        hasErr={hasErr}
        id={id}
        maxLength={100}
        autoComplete="false"
        type={isShow ? "text" : "password"}
        onFocus={handleFocus(id)}
        {...register}
        onBlur={handleFocus("")}
      />
      <S.PasswordShowButton type="button" onClick={handleClick}>
        {isShow ? (
          <EyeAbledIcon css={S.eyeIcon(isShow)} />
        ) : (
          <EyeDisabledIcon css={S.eyeIcon(isShow)} />
        )}
      </S.PasswordShowButton>
    </>
  );
};

export default AccountLabelInput;
