import { useCallback } from "react";
import dayjs from "dayjs";

const useFreeDate = (
  currentDate: dayjs.Dayjs[] | [],
  date: dayjs.Dayjs,
  hoveredDate: dayjs.Dayjs | string,
  changeHoveredDate: (date: dayjs.Dayjs | string) => void,
  handleClick: (date: dayjs.Dayjs[]) => () => void,
  isThisMonth?: boolean,
  changeMonth?: () => void,
) => {
  const isToday = dayjs().isSame(date, "d");
  const isSelectedDates =
    date.isSameOrAfter(currentDate[0]) &&
    date.isSameOrBefore(currentDate[currentDate.length - 1]);
  const isSelectedStartDate =
    currentDate.length !== 0 && currentDate[0].isSame(date, "d");
  const isSelectedEndDate =
    currentDate.length !== 0 &&
    currentDate[currentDate.length - 1].isSame(date, "d");

  const isHoveredBeforeSelectedDate =
    date.isSameOrBefore(currentDate[0]) && date.isSameOrAfter(hoveredDate);
  const isHoveredAfterSelectedDate =
    date.isSameOrAfter(currentDate[0]) && date.isSameOrBefore(hoveredDate);
  const isHoveredIncluded =
    !!isHoveredBeforeSelectedDate || !!isHoveredAfterSelectedDate;

  const weekIdx = date.get("d");

  const getNotSelectedDate = (date: dayjs.Dayjs) => {
    return currentDate.filter(
      (item) => item.format("YYYY-MM-DD") !== date.format("YYYY-MM-DD"),
    );
  };

  const handleFreeClick = (date: dayjs.Dayjs) => {
    const filteredDate = getNotSelectedDate(date);

    if (filteredDate.length !== currentDate.length) {
      filteredDate.length ? changeHoveredDate(date) : changeHoveredDate("");

      handleClick(filteredDate)();

      return;
    }

    if (currentDate.length === 1) {
      handleClick([...currentDate, date])();
      changeHoveredDate(currentDate[0]);
    } else if (currentDate.length === 0) {
      handleClick([date])();
      changeHoveredDate(date);
    } else {
      if (date.isSameOrBefore(currentDate[0])) {
        handleClick([date, currentDate[1]])();
      } else {
        handleClick([date, currentDate[0]])();
      }
    }
  };

  const handleDateClick = (date: dayjs.Dayjs) => () => {
    handleFreeClick(date);

    if (!isThisMonth) {
      changeMonth && changeMonth();
    }
  };

  const handleMouseOver = useCallback(
    (date: dayjs.Dayjs) => () => {
      currentDate.length === 1
        ? changeHoveredDate && changeHoveredDate(date)
        : changeHoveredDate && changeHoveredDate("");
    },
    [currentDate],
  );

  return {
    isToday,
    isSelectedDates,
    isSelectedStartDate,
    isSelectedEndDate,
    isHoveredIncluded,
    isHoveredBeforeSelectedDate,
    isHoveredAfterSelectedDate,
    weekIdx,
    handleMouseOver,
    handleDateClick,
  };
};

export default useFreeDate;
