import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

import { DEVICE_DETAIL_TABLE_GRID_COLUMNS } from "assets";

const mixin_horizontal_scrollbar = (theme: Theme) => css`
  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 8px;
    height: 6px;
    background-color: ${theme.color.gray_20};
  }

  ::-webkit-scrollbar-thumb {
    margin-right: 2px;
    border-radius: 100px;
    background-color: ${theme.color.gray_40};
  }
`;

export const deviceDetailTable = (theme: Theme) => css`
  ${mixin_horizontal_scrollbar(theme)};
  display: block;
  height: 888px; // NOTE: 가로스크롤이 생김에 따라 기존 883px + 가로스크롤 높이(5px)

  & > thead {
    position: static;
  }

  & > tbody {
    height: 820px;
  }

  tr {
    grid-template-columns: ${DEVICE_DETAIL_TABLE_GRID_COLUMNS};
  }
`;

export const ListWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    border: 1px solid ${theme.color.gray_20};
    overflow: auto;
  `}
`;

export const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 23px;
`;

export const LastUpdatedWrapper = styled.div`
  display: flex;
`;

export const mapButton = (theme: Theme) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${theme.color.gray_20};
  border-radius: 2px;
  padding: 0;
  background-color: ${theme.color.gray_10};

  & > svg {
    width: 22px;
    height: 22px;
  }
`;

export const LastUpdated = styled.span`
  ${({ theme }) => css`
    ${theme.font.medium_13};
    margin-right: 4px;
    color: ${theme.color.gray_60};
  `}
`;

export const LastUpdatedAt = styled.span`
  ${({ theme }) => css`
    ${theme.font.medium_13};
    margin-right: 2px;
    color: ${theme.color.gray_80};
  `}
`;

export const UpdateButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;

  & > svg {
    width: 12px;
    height: 12px;
  }
`;

export const resetIcon = (theme: Theme) => css`
  & > path {
    fill: ${theme.color.gray_70};
  }
`;

export const ListItemHeader = styled.div`
  ${({ theme }) => css`
    position: sticky;
    top: 0px;
    display: grid;
    grid-template-columns: ${DEVICE_DETAIL_TABLE_GRID_COLUMNS};
    width: fit-content;
    padding: 0 12px;
    background-color: ${theme.color.gray_10};
  `}
`;

export const ListItemHeaderItem = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_13};
    padding: 10px 12px;
    color: ${theme.color.gray_60};
    background-color: ${theme.color.gray_10};
  `}
`;

export const ListItemWrapper = styled.ul`
  height: 500px;
`;

export const ListItem = styled.li`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: ${DEVICE_DETAIL_TABLE_GRID_COLUMNS};
    width: fit-content;
    border-bottom: 1px solid ${theme.color.gray_20};
    padding: 0 12px;

    &:last-of-type {
      border-bottom: 0;
    }
  `}
`;

export const Item = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_13};
    padding: 10px 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
`;

export const noResult = (theme: Theme) => css`
  & > svg {
    margin-bottom: 8px;
  }

  & > div > p {
    ${theme.font.regular_14};
  }
`;
