import React from "react";

import { SuccessIcon } from "@/assets";

import * as S from "./ConnectStatus.styled";

interface ConnectStatusProps {
  isConnected: boolean;
}

const ConnectStatus = ({ isConnected }: ConnectStatusProps) => {
  return (
    <S.Align>
      <SuccessIcon css={S.connectIconStatus(isConnected)} />
    </S.Align>
  );
};

export default ConnectStatus;
