/** @jsxImportSource @emotion/react */ // NOTE: 공용 컴포넌트에서 className으로 수정하려고 하면 필요
import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash-es";

import { Checkbox, NoResult, Portal, Tooltip } from "@/components";
import { useTableScrollTop } from "@/hooks";
import { isInitFilterAtom } from "@/stores";
import { NoneSearchIcon, ErrorIcon } from "@/assets";
import type { ColumnTable, ColumnTooltip } from "@/types";

import * as S from "./Table.styled";

interface TableProps {
  className?: string;
  gridTemplateColumns: string;
  isLoading?: boolean;
  isInitFilter?: boolean;
  children: React.ReactNode;
  title?: string;
  columnTable: ColumnTable;
  columnTooltip?: ColumnTooltip;
  hasCheckbox?: boolean;
  isAllChecked?: boolean;
  handleAllCheck?: () => void;
}

interface RowProps {
  className?: string;
  children: React.ReactNode;
  handleMouseOver?: React.MouseEventHandler<HTMLElement>;
  handleMouseLeave?: React.MouseEventHandler<HTMLElement>;
}

interface SelectRowProps extends RowProps {
  id: string;
  isSelected?: boolean;
  selectFn: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

interface SelectRowMovePageProps extends RowProps {
  id: string;
  isSelected?: boolean;
  path: string;
  isOpenNewTab?: boolean;
  handleMouseOver?: React.MouseEventHandler<HTMLElement>;
  handleMouseLeave?: React.MouseEventHandler<HTMLElement>;
}

interface TdProps {
  className?: string;
  children: React.ReactNode;
}

const Table = ({
  className,
  children,
  isLoading,
  isInitFilter,
  title = "",
  columnTable,
  columnTooltip,
  hasCheckbox,
  isAllChecked,
  gridTemplateColumns,
  handleAllCheck,
}: TableProps) => {
  const { t } = useTranslation();

  const { tableRef } = useTableScrollTop();

  return (
    <S.Table
      className={className}
      gridTemplateColumns={gridTemplateColumns}
      ref={tableRef}
    >
      <caption className="a11y">{title}</caption>
      <thead>
        <S.HeadRow>
          {hasCheckbox && (
            <th>
              <Checkbox isChecked={isAllChecked} handleCheck={handleAllCheck} />
            </th>
          )}
          {Object.entries(columnTable).map(([key, label], i) => (
            <th key={i} title={label}>
              {t(label)}
              {columnTooltip?.[key] && (
                <Tooltip
                  css={S.headerTooltip}
                  position={columnTooltip[key].position}
                  message={columnTooltip[key].message}
                />
              )}
            </th>
          ))}
        </S.HeadRow>
      </thead>
      <S.Tbody>
        {isInitFilter ? (
          <Table.InitData />
        ) : isEmpty(children) && !isLoading ? (
          <Table.NoData />
        ) : (
          children
        )}
      </S.Tbody>
    </S.Table>
  );
};

Table.Row = function Row({
  className,
  children,
  handleMouseOver,
  handleMouseLeave,
}: RowProps) {
  return (
    <S.Row
      className={className}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </S.Row>
  );
};

Table.SelectRow = function SelectRow({
  className,
  children,
  id,
  isSelected = false,
  selectFn,
}: SelectRowProps) {
  const [domReady, setDomReady] = useState(false);

  const rowId = `table-row-${id}`;

  useEffect(() => {
    setDomReady(true);
  }, []);

  return (
    <>
      <S.SelectRow className={className} id={rowId} isSelected={isSelected}>
        {children}
      </S.SelectRow>
      <Portal container={`#${rowId} > td`} mounted={domReady}>
        <S.RowButton type="button" onClick={selectFn}>
          <span className="a11y">select row</span>
        </S.RowButton>
      </Portal>
    </>
  );
};

Table.SelectRowMovePage = function SelectRowMovePage({
  className,
  children,
  id,
  isSelected = false,
  path,
  isOpenNewTab,
  handleMouseOver,
  handleMouseLeave,
}: SelectRowMovePageProps) {
  const [isInitFilter, setIsInitFilter] = useRecoilState(isInitFilterAtom);

  const [domReady, setDomReady] = useState(false);

  const rowId = `table-row-${id}`;

  const handleLinkClick = () => {
    if (isInitFilter) return;

    setIsInitFilter(true);
  };

  useEffect(() => {
    setDomReady(true);
  }, []);

  return (
    <>
      <S.SelectRow className={className} id={rowId} isSelected={isSelected}>
        {children}
      </S.SelectRow>
      <Portal container={`#${rowId} > td`} mounted={domReady}>
        <S.RowLink
          to={path}
          target={isOpenNewTab ? "_blank" : "_self"}
          onClick={handleLinkClick}
          onMouseEnter={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        >
          <span className="a11y">select row</span>
        </S.RowLink>
      </Portal>
    </>
  );
};

Table.Td = function Td({ className, children }: TdProps) {
  return <S.Td className={className}>{children}</S.Td>;
};

Table.NoData = function NoData() {
  const { t } = useTranslation();

  return (
    <S.NoData>
      <td>
        <ErrorIcon css={S.noResultIcon} />
        {t("No results found")}
      </td>
    </S.NoData>
  );
};

Table.InitData = function InitData() {
  const { t } = useTranslation();

  return (
    <S.NoData>
      <td>
        <NoneSearchIcon css={S.noResultIcon} />
        {t("Please apply the filter to search")}
      </td>
    </S.NoData>
  );
};

Table.NoResultTr = function NoResultTr() {
  return (
    <S.NoResultTr>
      <td>
        <NoResult contents={["No results found"]} type="search" />
      </td>
    </S.NoResultTr>
  );
};

export default Table;
