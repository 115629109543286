/** @jsxImportSource @emotion/react */ // NOTE: 공용 컴포넌트에서 className으로 수정하려고 하면 필요
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

import { useDropdown } from "@/hooks";
import { CheckIcon, ChevronDownIcon, ErrorIcon } from "@/assets";
import type { DropdownOptionType, Languages } from "@/types";

import DropdownSkeleton from "./Dropdown.skeleton";
import * as S from "./Dropdown.styled";

interface DropdownProps {
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  optionArr: readonly DropdownOptionType[];
  placeholder?: Languages;
  selectedOption: DropdownOptionType;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  handleSelect?: (value: string) => void;
  handleConditionFocus?: (e?: React.FocusEvent<HTMLInputElement>) => void;
  handleConditionBlur?: (e?: React.FocusEvent<HTMLInputElement>) => void;
}

const Dropdown = ({
  className,
  isLoading = false,
  disabled,
  hasError,
  optionArr,
  placeholder,
  selectedOption,
  Icon = ChevronDownIcon,
  handleSelect,
  handleConditionFocus,
  handleConditionBlur,
}: DropdownProps) => {
  const { dropdownRef, optionsRef, isOpen, handleOpener, handleOptionClick } =
    useDropdown(
      selectedOption.key,
      handleSelect,
      handleConditionFocus,
      handleConditionBlur,
    );
  const id = uuidv4();

  const { t } = useTranslation();

  return (
    <S.Dropdown ref={dropdownRef} className={className}>
      <S.DropdownButton
        aria-controls={id}
        aria-expanded={isOpen}
        hasError={hasError}
        disabled={disabled}
        type="button"
        onClick={handleOpener}
      >
        {selectedOption?.label ? (
          <S.SelectedValue>{t(selectedOption.label)}</S.SelectedValue>
        ) : (
          placeholder && <S.Placeholder>{t(placeholder)}</S.Placeholder>
        )}
        <Icon />
      </S.DropdownButton>
      <S.OptionWrapper
        ref={optionsRef}
        aria-hidden={!isOpen}
        id={id}
        isOpen={isOpen}
      >
        {isLoading ? (
          [...Array(10)].map((_, i) => (
            <S.Option key={i}>
              <DropdownSkeleton />
            </S.Option>
          ))
        ) : optionArr.length === 0 ? (
          <S.NoOptionData>
            <ErrorIcon css={S.noOptionIcon} />
            <S.noOptionDataMsg>{t("No history")}</S.noOptionDataMsg>
          </S.NoOptionData>
        ) : (
          optionArr.map(({ key, label }) => {
            const isSelected = selectedOption?.key === key;

            return (
              <S.Option key={key}>
                <S.OptionButton
                  type="button"
                  onClick={handleOptionClick(key)}
                  isSelected={isSelected}
                >
                  <span>{t(label)}</span>
                  <CheckIcon css={S.singleDropdownCheckIcon(isSelected)} />
                </S.OptionButton>
              </S.Option>
            );
          })
        )}
      </S.OptionWrapper>
    </S.Dropdown>
  );
};

export default Dropdown;
