/** @jsxImportSource @emotion/react */ // NOTE: 공용 컴포넌트에서 className으로 수정하려고 하면 필요
import React from "react";
import { Link } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AccountLabelInput, Button, ErrorMessage } from "@/components";
import { COMMON_ERROR_MSG } from "@/constants";
import type { LoginFormType } from "@/types";

import * as S from "./LoginForm.styled";

interface LoginFormProps {
  logoIcon: React.ReactNode;
  resetPasswordPath?: string;
  handleLogin: (data: LoginFormType) => void;
}

const LoginForm = ({
  logoIcon,
  resetPasswordPath,
  handleLogin,
}: LoginFormProps) => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    watch,
    register,
    handleSubmit,
  } = useFormContext<LoginFormType>();

  return (
    <S.LoginSection>
      <h1 className="a11y">Login Page</h1>
      {logoIcon}
      <S.Form onSubmit={handleSubmit(handleLogin)}>
        <S.Wrapper>
          <AccountLabelInput id="email" label="Email" value={watch("email")}>
            <AccountLabelInput.Input
              hasErr={!!errors.email?.message}
              register={register("email", {
                required: COMMON_ERROR_MSG.EMAIL,
              })}
            />
          </AccountLabelInput>
          <AccountLabelInput
            id="password"
            label="Password"
            value={watch("password")}
          >
            <AccountLabelInput.Password
              hasErr={!!errors.password?.message}
              register={register("password", {
                required: COMMON_ERROR_MSG.PASSWORD,
              })}
            />
          </AccountLabelInput>
        </S.Wrapper>
        {(errors.email?.message || errors.password?.message) && (
          <ErrorMessage
            message={
              (errors.email?.message || errors.password?.message) as string
            }
          />
        )}
        <Button
          css={S.loginButton}
          type="submit"
          label="Sign in"
          variant="primary"
        />
        {resetPasswordPath && (
          <Link css={S.resetPasswordLink} to={resetPasswordPath}>
            {t("Reset password")}
          </Link>
        )}
      </S.Form>
    </S.LoginSection>
  );
};

export default LoginForm;
