/** @jsxImportSource @emotion/react */ // NOTE: 공용 컴포넌트에서 className으로 수정하려고 하면 필요
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import type { UseFormRegisterReturn } from "react-hook-form";

import { EyeAbledIcon, EyeDisabledIcon } from "@/assets";
import type { Languages } from "@/types";

import * as S from "./PasswordInput.styled";

interface PasswordInputProps {
  className?: string;
  hasError?: boolean;
  placeholder: Languages;
  register: UseFormRegisterReturn<string>;
}

const PasswordInput = ({
  className,
  hasError,
  placeholder,
  register,
}: PasswordInputProps) => {
  const { t } = useTranslation();

  const [isShow, setIsShow] = useState(false);

  const handleClick = (): void => {
    setIsShow(!isShow);
  };

  return (
    <>
      <S.PasswordInput
        className={className}
        // NOTE: translation 결과 타입에 'null' 이 잡혀서 에러 발생
        placeholder={t(placeholder)!}
        maxLength={20}
        type={isShow ? "text" : "password"}
        hasError={hasError}
        {...register}
      />
      <S.PasswordShowButton type="button" onClick={handleClick}>
        {isShow ? (
          <EyeAbledIcon css={S.eyeIcon(isShow)} />
        ) : (
          <EyeDisabledIcon css={S.eyeIcon(isShow)} />
        )}
      </S.PasswordShowButton>
    </>
  );
};

export default PasswordInput;
