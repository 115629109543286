import type { FileRequestType } from "@/types";

import { ax } from "apis";

export const getS3PresignedUrlAPI = async (
  s3FileKey: string,
): Promise<{ url: string }> => {
  if (!s3FileKey) return { url: "" };
  const { data } = await ax.get(`/presigned/${s3FileKey}`);

  return data;
};

export const getFileAPI = async (req: FileRequestType) => {
  const { data } = await ax.get<Blob>(`/iot/${req.fileKey}/certificates`, {
    responseType: "blob",
  });

  return data;
};
