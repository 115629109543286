import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import {
  getIoTDeviceAPI,
  getIoTDeviceDetailAPI,
  getDeviceLocationAPI,
  deleteIoTDeviceAPI,
} from "apis";
import type {
  DeleteIoTDeviceQueryModel,
  GetIoTDeviceDetailQueryModel,
  GetIoTDeviceDetailServerModel,
  GetIoTDeviceQueryModel,
} from "types";

export const deviceKeys = {
  all: ["device"] as const,
  lists: () => [...deviceKeys.all, "list"] as const,
  list: (filters: GetIoTDeviceQueryModel) =>
    [...deviceKeys.lists(), filters] as const,
  details: () => [...deviceKeys.all, "detail"] as const,
  detail: (req: GetIoTDeviceDetailQueryModel) =>
    [...deviceKeys.details(), req] as const,
  coord: (iotId: string) => [...deviceKeys.details(), iotId, "coord"] as const,
};

export const useGetIoTDevice = (req: GetIoTDeviceQueryModel) => {
  return useQuery({
    queryKey: deviceKeys.list(req),
    queryFn: () => getIoTDeviceAPI(req),
  });
};

export const useGetIoTDeviceDetail = (
  req: GetIoTDeviceDetailQueryModel,
  enabled: boolean,
) =>
  useInfiniteQuery({
    queryKey: deviceKeys.detail(req),
    queryFn: ({ pageParam }) =>
      getIoTDeviceDetailAPI({
        id: req.id,
        query: { date: req.query.date, nextCursor: pageParam },
      }),
    enabled,
    getNextPageParam: (lastPage: GetIoTDeviceDetailServerModel) =>
      lastPage.nextCursor,
  });

export const useGetDeviceLocation = (iotId: string) =>
  useQuery({
    queryKey: deviceKeys.coord(iotId),
    queryFn: () => getDeviceLocationAPI(iotId),
    suspense: false,
    refetchInterval: 5000,
  });

export const useDeleteIoTDevice = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: DeleteIoTDeviceQueryModel) => deleteIoTDeviceAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: deviceKeys.lists() });
    },
  });
};
