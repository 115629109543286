/** @jsxImportSource @emotion/react */ // NOTE: 공용 컴포넌트에서 className으로 수정하려고 하면 필요
import React from "react";
import { useTranslation } from "react-i18next";

import { LoadingSpinner } from "@/components";
import type { Languages } from "@/types";

import * as S from "./Button.styled";

export type ButtonVariant =
  | "primary"
  | "secondary"
  | "third"
  | "error"
  | "outlined"
  | "iconOnly";

interface ButtonProps {
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  as?: "span";
  type?: "button" | "submit" | "reset";
  variant: ButtonVariant;
  label?: Languages;
  icon?: React.ReactNode;
  handleButtonClick?: (e: React.MouseEvent) => void;
}

const Button = ({
  className,
  isDisabled,
  isLoading = false,
  as,
  icon,
  label,
  type = "button",
  variant,
  handleButtonClick,
}: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <S.Button
      className={className}
      isLoading={isLoading}
      disabled={isDisabled}
      as={as}
      type={type}
      variant={variant}
      onClick={handleButtonClick}
    >
      {isLoading ? (
        <>
          <LoadingSpinner css={S.loadingSpinner} />
          <S.LoadingContents>
            {icon && icon}
            {label && t(label)}
          </S.LoadingContents>
        </>
      ) : (
        <>
          {icon && icon}
          {label && t(label)}
        </>
      )}
    </S.Button>
  );
};

export default Button;
