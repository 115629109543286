import React from "react";
import { useParams } from "react-router-dom";

import { GoogleMap } from "@/components";

import { useGetDeviceLocation } from "services";
import { useDeviceRecentLocationMarker } from "./hooks";
import * as S from "./DeviceRecentLocationMap.styled";

const DeviceRecentLocationMap = () => {
  const { deviceId } = useParams();

  const { data: recentLatLng } = useGetDeviceLocation(deviceId!);

  useDeviceRecentLocationMarker(recentLatLng);

  return <GoogleMap css={S.googleMap} />;
};

export default DeviceRecentLocationMap;
