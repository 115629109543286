import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import { NotFound } from "@/components";

import * as P from "pages";
import { AuthRoute, PrivateLayout, PublicRoute } from "components";
import { PATH } from "constants/index";

interface RouterProps {
  children: React.ReactNode;
}

const Router = ({ children }: RouterProps) => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={children}>
        <Route element={<PublicRoute />}>
          <Route path={PATH.ROOT} element={<P.Login />} />
        </Route>
        <Route element={<AuthRoute />}>
          <Route path="*" element={<NotFound path={PATH.ROOT} />} />
          <Route element={<PrivateLayout />}>
            <Route path={PATH.DEVICE}>
              <Route path="" element={<P.Device />} />
              <Route path=":deviceId">
                <Route path="" element={<P.DeviceDetail />} />
                <Route path="map" element={<P.DeviceRecentLocationMap />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
};

export default Router;
