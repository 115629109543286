import axios from "axios";

import { renewAccessTokenAPI } from "@/apis";
import { AUTH_ALERT_MSG, COMMON_ERROR_MSG } from "@/constants";
import type { KokkokServiceName } from "@/types";

const RENEW_ACCESS_TOKEN_API_URL = {
  admin: `${process.env.REACT_APP_BASE_URL}/admins/tokens`,
  logistics: `${process.env.REACT_APP_BASE_URL}/auth/tokens`,
  iot: `${process.env.REACT_APP_BASE_URL}/iot/tokens`,
  carAdmin: `${process.env.REACT_APP_BASE_URL}/carAdmin/tokens`,
};

interface Fn {
  (accessToken: string): void;
}

interface TokenServiceProps {
  accessToken: string | undefined;
  refreshToken: string | undefined;
  changeAccessToken: (accessToken: string) => void;
  signOut: () => void;
}

interface InstanceProps {
  serviceName: KokkokServiceName;
  auth: TokenServiceProps;
}

export class TokenService {
  private isAlreadyFetchingAccessToken = false;
  private subscribers: Fn[] = [];

  private static instance: TokenService | null = null;

  private constructor(
    private serviceName: KokkokServiceName,
    private auth: TokenServiceProps,
  ) {}

  static getInstance({ serviceName, auth }: InstanceProps): TokenService {
    if (!TokenService.instance) {
      TokenService.instance = new TokenService(serviceName, auth);
    }
    return TokenService.instance;
  }

  async resetTokenAndReattemptRequest(error: any) {
    try {
      const { response: errorResponse } = error;

      const { refreshToken } = this.auth;
      if (!refreshToken) {
        this.expireSession(errorResponse?.data.message);
        return await Promise.reject(error);
      }
      const retryOriginalRequest = new Promise((resolve) => {
        this.addSubscriber((accessToken: string) => {
          errorResponse.config.headers.Authorization = `Bearer ${accessToken}`;
          resolve(axios(errorResponse.config));
        });
      });
      if (!this.isAlreadyFetchingAccessToken) {
        try {
          this.isAlreadyFetchingAccessToken = true;
          const tokens = await renewAccessTokenAPI(
            RENEW_ACCESS_TOKEN_API_URL[this.serviceName],
            refreshToken,
          );
          if (!tokens.accessToken) {
            return await Promise.reject(error);
          }

          this.auth.changeAccessToken(tokens.accessToken);

          this.onAccessTokenFetched(tokens.accessToken);
        } catch (err: any) {
          const { response: errorResponse } = err;

          const authErrorCode = [
            COMMON_ERROR_MSG.INVALID_REFRESH_TOKEN,
            COMMON_ERROR_MSG.DUPLICATED_SIGNIN_DETECTED, // TODO: move admin, iot 서버 반영 및 웹에 배포 완료 후 삭제 필요
            COMMON_ERROR_MSG.DUPLICATE_SIGNIN_DETECTED,
          ];

          if (authErrorCode.includes(errorResponse?.data.message)) {
            this.expireSession(errorResponse?.data.message);
            return await Promise.reject(err);
          }
        } finally {
          this.isAlreadyFetchingAccessToken = false;
        }
      }
      return await retryOriginalRequest;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  getAccessToken() {
    return this.auth.accessToken;
  }

  onAccessTokenFetched(accessToken: string) {
    this.subscribers.forEach((callback) => callback(accessToken));
    this.subscribers = [];
  }

  addSubscriber(callback: Fn) {
    this.subscribers.push(callback);
  }

  expireSession(errorMessage: keyof typeof AUTH_ALERT_MSG) {
    this.auth.refreshToken && alert(AUTH_ALERT_MSG[errorMessage]);
    this.auth.signOut();
  }
}
