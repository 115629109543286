import { upperFirst, includes } from "lodash-es";

import { formatPeriodToUTC } from "@/utils";
import type { StringKeyOf } from "@/types";

export const getFilterParams = <T>(
  searchParams: URL["searchParams"],
  params: readonly StringKeyOf<T>[],
  init?: Partial<T>,
  isLocalTime: boolean = false,
) => {
  return Object.keys(Object.fromEntries(searchParams)).reduce((acc, query) => {
    if (!includes(params, query)) return acc;

    const queryValue = searchParams.getAll(query);
    const value = queryValue.join(",");
    const dateQueries = ["date", "pickup", "updated", "created", "completed"];

    if (dateQueries.includes(query)) {
      const [startDate, endDate] = value
        ? value.split("~").map((item) => item.trim())
        : [null, null];

      /* NOTE: 
           'date' -> 'startDate', 'endDate'
           'created' -> 'createdStart', 'createdEnd' 여서 하기와 같이 처리
        */
      const filterStartKey =
        query === "date" ? `start${upperFirst(query)}` : `${query}Start`;
      const filterEndKey =
        query === "date" ? `end${upperFirst(query)}` : `${query}End`;

      return {
        ...acc,
        ...(startDate && {
          [filterStartKey]: formatPeriodToUTC(
            "startDate",
            startDate,
            isLocalTime,
          ),
        }),
        ...(endDate && {
          [filterEndKey]: formatPeriodToUTC("endDate", endDate, isLocalTime),
        }),
      };
    }

    return {
      ...acc,
      [query]: value,
    };
  }, getDefaultQuery(init) as unknown as T);
};

const getDefaultQuery = <T>(init?: T) => ({
  page: "1",
  pageSize: "20",
  ...(init ?? {}),
});

export const getAllQuery = (searchParams: URL["searchParams"]) => {
  return Object.keys(Object.fromEntries(searchParams)).reduce<{
    [key: string]: string[];
  }>(
    (acc, query) => ({
      ...acc,
      [query]: searchParams.getAll(query),
    }),
    {},
  );
};

export const selectFilterName = (
  filterKeyValues: { searchLabel: { key: string; name: string }[] }[],
  selectLabel: string,
) =>
  filterKeyValues
    .map(
      (filterKeyValue) =>
        filterKeyValue.searchLabel.filter(
          (item) => item.key === selectLabel,
        )[0],
    )
    .filter((item) => !!item)[0].name;

export const deleteQueryKeys = <T extends object, K extends keyof T>(
  req: { query: T },
  keys: K[],
): { query: Omit<T, K> } => {
  const newQuery = { ...req.query };

  keys.forEach((key) => {
    delete newQuery[key];
  });

  return { query: newQuery };
};
