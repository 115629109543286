import React from "react";

import { ConfirmModal } from "@/components";
import { useModal } from "@/hooks";
import type { Languages } from "@/types";

import { useDeleteIoTDevice } from "services";

interface DeleteIotModalProps {
  iotId: string;
}

const DeleteIotModal = React.forwardRef<HTMLDialogElement, DeleteIotModalProps>(
  ({ iotId }, ref) => {
    const { mutate: deleteIotDevice } = useDeleteIoTDevice();

    const { handleModalClose } = useModal();

    const handleDeleteIot = (): void => {
      deleteIotDevice({ body: { iotId } }, { onSuccess: handleModalClose });
    };

    return (
      <ConfirmModal
        ref={ref}
        title={"Delete IoT data" as Languages}
        desc={
          "Are you sure you want to delete the IoT data? You can't undo this action." as Languages
        }
        buttonType="alert"
        activeButtonName="Delete"
        activeFn={handleDeleteIot}
      />
    );
  },
);

DeleteIotModal.displayName = "DeleteIotModal";

export default DeleteIotModal;
