/** @jsxImportSource @emotion/react */ // NOTE: 공용 컴포넌트에서 className으로 수정하려고 하면 필요
import React, { memo } from "react";
import dayjs from "dayjs";
import type { UseFormRegisterReturn } from "react-hook-form";

import Calendar from "@/components/calendar/Calendar";
import { CalendarButton, Input } from "@/components";
import { CalendarIcon } from "@/assets";

import * as S from "./CalendarInput.styled";

interface CalendarInputProps {
  className?: string;
  dialogPosition: "up" | "down";
  hasError?: boolean;
  hasTime?: boolean;
  disabled?: boolean;
  selectedDate: string[];
  type?: "date" | "free";
  value: string;
  register?: UseFormRegisterReturn<string>;
  handleDateChange: (date: dayjs.Dayjs[] | []) => void;
  handleConditionFocus?: (e?: React.FocusEvent<HTMLInputElement>) => void;
  handleConditionBlur?: (e?: React.FocusEvent<HTMLInputElement>) => void;
}

const CalendarInput = ({
  className,
  dialogPosition,
  hasError,
  hasTime,
  disabled,
  selectedDate,
  type,
  value,
  register,
  handleDateChange,
  handleConditionFocus,
  handleConditionBlur,
}: CalendarInputProps) => {
  return (
    <S.Root className={className}>
      <Input
        css={S.calendarInput(disabled)}
        placeholder="Select the date"
        disabled
        value={value}
        hasError={hasError}
        register={register}
      />
      <CalendarButton
        css={S.calendarDialogButton(disabled)}
        disabled={disabled}
        popup={(dialogRef, isDialogOpen, handleDialogClose) => (
          <Calendar
            ref={dialogRef}
            dialogPosition={dialogPosition}
            isDialogOpen={isDialogOpen}
            hasTime={hasTime}
            as="dialog"
            type={type}
            selectedDate={selectedDate}
            handleDateChange={handleDateChange}
            handleDialogClose={handleDialogClose}
            handleConditionFocus={handleConditionFocus}
            handleConditionBlur={handleConditionBlur}
          />
        )}
      >
        <CalendarIcon css={S.calendarIcon} />
      </CalendarButton>
    </S.Root>
  );
};

export default memo(CalendarInput);
