import React from "react";
import { Outlet } from "react-router-dom";

import { GoogleMapProvider, RoutePathProvider } from "@/context";
import { Modal, Toast } from "@/components";

import Router from "router";
import { AuthProvider } from "contexts";
import { ErrorBoundary } from "components";
import { NON_SAVED_PATH } from "constants/index";

const Root = () => {
  return (
    <AuthProvider>
      <GoogleMapProvider>
        <ErrorBoundary>
          <Router>
            <Toast />
            <Modal />
            <RoutePathProvider nonSavedPath={NON_SAVED_PATH}>
              <ErrorBoundary>
                <Outlet />
              </ErrorBoundary>
            </RoutePathProvider>
          </Router>
        </ErrorBoundary>
      </GoogleMapProvider>
    </AuthProvider>
  );
};

export default Root;
