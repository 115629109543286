import type { Languages } from "@/types";

import { IotIcon } from "assets";
import { PATH } from "constants/index";

export const NAVS: {
  path: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  content: Languages;
}[] = [
  {
    path: PATH.DEVICE,
    Icon: IotIcon,
    content: "IoT" as Languages,
  },
];
