import React, { useEffect } from "react";
import {
  Outlet,
  Navigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { modalCompoState } from "@/stores";

import { useAuth } from "contexts";
import { PATH } from "constants/index";

const AuthRoute = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const setModalCompo = useSetRecoilState(modalCompoState);

  const { user, initializing } = useAuth();

  useEffect(() => {
    setModalCompo([]);
    document.body.style.cssText = "overflow: auto";
  }, [location.pathname, searchParams]);

  if (initializing) return null;
  if (!user) return <Navigate to={PATH.ROOT} state={location.pathname} />;

  return <Outlet />;
};

export default AuthRoute;
