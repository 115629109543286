import React, { useEffect } from "react";
import { useRecoilState } from "recoil";

import { Button, DataSection, DownloadButton, Table } from "@/components";
import { useFilterParams, useModal } from "@/hooks";
import { isInitFilterAtom } from "@/stores";
import { TrashIcon } from "@/assets";

import { useAuth } from "contexts";
import { ConnectStatus } from "components";
import { useDownloadFile, useGetIoTDevice } from "services";
import {
  DEVICE_FILTER_QUERIES,
  DEVICE_TABLE_GRID_COLUMNS,
  DEVICE_TABLE_HEADER,
  HAS_DELETE_DEVICE_TABLE_GRID_COLUMNS,
  HAS_DELETE_DEVICE_TABLE_HEADER,
} from "assets";
import { PATH } from "constants/index";
import type { GetIoTDeviceQueryModel, GetIoTDeviceServerModel } from "types";
import { DeleteIotModal } from "../deleteIotModal";
import * as S from "./DeviceSection.styled";

const DeviceSection = () => {
  const { filters } = useFilterParams<GetIoTDeviceQueryModel["query"]>(
    DEVICE_FILTER_QUERIES,
  );
  const [isInitFilter, setIsInitFilter] = useRecoilState(isInitFilterAtom);

  const req = { query: filters };

  const { data, dataUpdatedAt, refetch, remove } = useGetIoTDevice({
    query: filters,
  });

  const { mutate: downloadMutate } = useDownloadFile();

  const { modalRef, handleModalOpen } = useModal();
  const {
    auth: { user },
  } = useAuth();

  // NOTE: 권성일 이사님 계정으로만 delete 할 수 있도록 버튼 조건
  const isDeleteButtonView = user?.email === "samuel@coconutsilo.com";

  const formatTableData = (
    key:
      | keyof typeof DEVICE_TABLE_HEADER
      | keyof typeof HAS_DELETE_DEVICE_TABLE_HEADER,
    item: GetIoTDeviceServerModel["deviceInfo"][number],
  ) => {
    switch (key) {
      case "registered":
        return <ConnectStatus isConnected={!!item[key]} />;

      case "status":
        return <ConnectStatus isConnected={item[key] === "connected"} />;

      case "certificatesLink":
        return item.id ? (
          <DownloadButton
            css={S.downloadButton}
            label="Download"
            fileKey={item.id}
            fileName="Download"
            downloadFileMutate={downloadMutate}
          />
        ) : (
          "-"
        );

      case "delete":
        return (
          isDeleteButtonView && (
            <Button
              css={S.deleteButton}
              variant="iconOnly"
              icon={<TrashIcon css={S.trashIcon} />}
              handleButtonClick={handleDeleteIot(item.id)}
            />
          )
        );

      default:
        return item[key] || "-";
    }
  };

  const handleDeleteIot = (iotId: string) => (): void => {
    handleModalOpen(<DeleteIotModal ref={modalRef} iotId={iotId} />)();
  };

  useEffect(() => {
    if (!isInitFilter) return;

    setIsInitFilter(false);
  }, [req]);

  return (
    <DataSection
      totalData={data?.pageInfo.totalData || 0}
      dataUpdatedAt={dataUpdatedAt}
      isLocalTime
      refetch={refetch}
      remove={remove}
    >
      <Table
        gridTemplateColumns={
          isDeleteButtonView
            ? HAS_DELETE_DEVICE_TABLE_GRID_COLUMNS
            : DEVICE_TABLE_GRID_COLUMNS
        }
        columnTable={
          isDeleteButtonView
            ? HAS_DELETE_DEVICE_TABLE_HEADER
            : DEVICE_TABLE_HEADER
        }
      >
        {data?.deviceInfo.map((rowData) => (
          <Table.SelectRowMovePage
            key={rowData.id}
            id={`${rowData.id}`}
            path={`${PATH.DEVICE}/${rowData.id}`}
          >
            {Object.keys(
              isDeleteButtonView
                ? HAS_DELETE_DEVICE_TABLE_HEADER
                : DEVICE_TABLE_HEADER,
            ).map((key) => (
              <Table.Td
                css={key === "delete" && S.deleteButtonWrapper}
                key={key}
              >
                {formatTableData(
                  key as
                    | keyof typeof DEVICE_TABLE_HEADER
                    | keyof typeof HAS_DELETE_DEVICE_TABLE_HEADER,
                  rowData,
                )}
              </Table.Td>
            ))}
          </Table.SelectRowMovePage>
        ))}
      </Table>
    </DataSection>
  );
};

export default DeviceSection;
