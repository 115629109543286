import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import type { StringKeyOf } from "@/types";

import { getFilterParams } from "@/utils";

const useFilterParams = <T>(
  params: readonly StringKeyOf<T>[],
  init?: Partial<T>,
  isLocalTime: boolean = false,
) => {
  const [searchParams] = useSearchParams();

  const filters = useMemo(
    () => getFilterParams(searchParams, params, init, isLocalTime),
    [searchParams],
  );

  return { filters };
};

export default useFilterParams;
