import { useEffect, useRef } from "react";

import { useGetIoTDeviceDetail } from "services";
import type { GetIoTDeviceDetailQueryModel } from "types";

const useDeviceDetail = (
  req: GetIoTDeviceDetailQueryModel,
  isBoolean: boolean,
) => {
  const observerRef = useRef<HTMLLIElement | null>(null);

  const { hasNextPage, fetchNextPage, data, dataUpdatedAt, refetch, remove } =
    useGetIoTDeviceDetail(req, isBoolean);

  const onIntersect = ([entry]: IntersectionObserverEntry[]): void => {
    entry.isIntersecting && hasNextPage && fetchNextPage();
  };

  useEffect(() => {
    let observer: IntersectionObserver | undefined;

    if (observerRef && observerRef.current) {
      observer = new IntersectionObserver(onIntersect, {
        threshold: 0.1,
      });

      observer.observe(observerRef.current);
    }
    return () => {
      observer && observer.disconnect();
    };
  }, [observerRef, hasNextPage, onIntersect]);

  useEffect(() => {
    return () => remove();
  }, []);

  return {
    observerRef,
    data: data?.pages.flatMap(({ thingInfo }) => thingInfo),
    dataUpdatedAt,
    refetch,
  };
};

export default useDeviceDetail;
