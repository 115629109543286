/** @jsxImportSource @emotion/react */ // NOTE: 공용 컴포넌트에서 className으로 수정하려고 하면 필요
import React from "react";

import { Button } from "@/components";
import { CloseIcon } from "@/assets";

import * as S from "./SelectedLabel.styled";

interface SelectedLabelProps {
  children: React.ReactNode;
  handleLabelDelete: () => void;
}

const SelectedLabel = ({ children, handleLabelDelete }: SelectedLabelProps) => {
  return (
    <S.SearchLabel>
      {children}
      <Button
        css={S.closeButton}
        icon={<CloseIcon />}
        variant="iconOnly"
        handleButtonClick={handleLabelDelete}
      />
    </S.SearchLabel>
  );
};

export default SelectedLabel;
