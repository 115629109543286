/** @jsxImportSource @emotion/react */ // NOTE: 공용 컴포넌트에서 className으로 수정하려고 하면 필요
import React from "react";
import type { UseMutateFunction } from "@tanstack/react-query";

import {
  DetailModal,
  ErrorMessage,
  LabelContentTable,
  PasswordCondition,
  PasswordInput,
} from "@/components";
import type { ApiError, ChangeAccountPasswordQueryModel } from "@/types";

import useChangePassword from "./hooks/useChangePassword";
import * as S from "./ChangePasswordModal.styled";

interface PasswordChangeModalProps {
  className?: string;
  isLoading: boolean;
  changeAccountPasswordMutate: UseMutateFunction<
    unknown,
    ApiError,
    ChangeAccountPasswordQueryModel,
    unknown
  >;
}

const ChangePasswordModal = React.forwardRef<
  HTMLDialogElement,
  PasswordChangeModalProps
>(({ className, isLoading, changeAccountPasswordMutate }, ref) => {
  const { errors, touchedFields, watch, handlePasswordChange, register } =
    useChangePassword({
      changeAccountPasswordMutate,
    });

  return (
    <DetailModal
      ref={ref}
      css={S.modalLayout}
      className={className}
      isPosLoading={isLoading}
      title="Change password"
      posButtonName="Update"
      posFnType="submit"
      isPosDisabled={Object.keys(errors).length > 0}
      posFn={handlePasswordChange}
    >
      <LabelContentTable variant="empty" marginBottom={20}>
        <LabelContentTable.Row>
          <LabelContentTable.Content
            css={S.content}
            label="Current password"
            labelWidth={150}
            isRequired
          >
            <S.InputWrapper>
              <PasswordInput
                hasError={!!errors.currentPassword?.message}
                placeholder="Current password"
                register={register("currentPassword")}
              />
              {errors?.currentPassword?.message && (
                <ErrorMessage
                  css={S.errorMsg}
                  message={errors.currentPassword.message}
                />
              )}
            </S.InputWrapper>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content
            css={S.content}
            label="New password"
            labelWidth={150}
            isRequired
          >
            <S.InputWrapper>
              <PasswordInput
                hasError={!!errors.newPassword?.message}
                placeholder="Enter password"
                register={register("newPassword")}
              />
              <PasswordCondition
                css={S.passwordCondition}
                touchedFields={touchedFields}
                currentPassword={watch("currentPassword")}
                newPassword={watch("newPassword")}
              >
                <PasswordCondition.lengthCondition />
                <PasswordCondition.textTypeCondition />
                <PasswordCondition.passwordCondition />
              </PasswordCondition>
            </S.InputWrapper>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content
            css={S.content}
            label="Confirm password"
            labelWidth={150}
            isRequired
          >
            <S.InputWrapper>
              <PasswordInput
                hasError={!!errors.confirmPassword?.message}
                placeholder="Enter confirm password"
                register={register("confirmPassword")}
              />
              <PasswordCondition
                css={S.passwordCondition}
                touchedFields={touchedFields}
                newPassword={watch("newPassword")}
                confirmPassword={watch("confirmPassword")}
              >
                <PasswordCondition.checkConfirmPassword />
              </PasswordCondition>
            </S.InputWrapper>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
    </DetailModal>
  );
});

ChangePasswordModal.displayName = "ChangePasswordModal";

export default ChangePasswordModal;
