import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const LabelFieldTable = styled.table`
  ${({ theme }) => css`
    display: grid;
    row-gap: 1px;
    height: max-content;
    border: 1px solid ${theme.color.gray_20};
    background-color: ${theme.color.gray_20};
  `}
`;

export const Caption = styled.caption`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    display: flex;
    align-items: center;
    width: 100%;
    padding: 13px 20px;
    color: ${theme.color.gray_60};
    background-color: ${theme.color.gray_10};
  `}
`;

export const Tbody = styled.tbody`
  display: grid;
  row-gap: 1px;
`;

export const EmptyWrapper = styled.tr<{ height: number }>`
  ${({ theme, height }) => css`
    display: flex;
    width: 100%;
    height: ${height}px;
    background-color: ${theme.color.white};

    & > td {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  `}
`;

export const Row = styled.tr`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 210px 1fr;
    width: 100%;

    & > th {
      ${theme.font.medium_14};
      display: flex;
      align-items: center;
      padding: 13px 20px;
      color: ${theme.color.gray_60};
      white-space: pre;
      background-color: ${theme.color.gray_10};
    }

    & > td {
      ${theme.font.regular_14};
      display: flex;
      align-items: center;
      padding: 13px 20px;
      color: ${theme.color.black};
      background-color: ${theme.color.white};
    }
  `}
`;

export const Bottom = styled.tr`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    padding: 16px;
    background-color: ${theme.color.white};

    & > td {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;
    }
  `}
`;

export const tip = css`
  margin-left: 8px;
`;

export const labelFieldTableRowSkeleton = (gridTemplateColumns?: number) => css`
  grid-template-columns: ${gridTemplateColumns || 210}px 1fr;

  & > th,
  td {
    height: 48px;
  }
`;

export const searchDataIcon = (theme: Theme) => css`
  & > path {
    fill: ${theme.color.gray_40};
  }
`;
