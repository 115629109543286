import React from "react";
import { useTranslation } from "react-i18next";
import type { AxiosResponse } from "axios";
import type { UseMutateFunction } from "@tanstack/react-query";

import { GhostButton } from "@/components";
import type { ApiError, FileRequestType, Languages } from "@/types";

interface DownloadButtonProps {
  className?: string;
  fileName: string;
  fileKey: string;
  label: Languages;
  downloadFileMutate: UseMutateFunction<
    // NOTE: res 자체를 Blob으로 념겨주고 있어서 해당과 같이 타입 설정
    AxiosResponse<any, any> | Blob,
    ApiError,
    FileRequestType,
    unknown
  >;
}

const DownloadButton = ({
  className,
  fileName,
  fileKey,
  label,
  downloadFileMutate,
}: DownloadButtonProps) => {
  const { t } = useTranslation();

  const handleDownload = (): void => {
    downloadFileMutate({ fileKey, fileName });
  };

  return (
    <GhostButton
      className={className}
      type="button"
      variant="ghost_blue"
      label={t(label) as Languages}
      handleButtonClick={handleDownload}
    />
  );
};

export default DownloadButton;
