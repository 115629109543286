import { atom } from "recoil";

import type { Toast } from "@/types";

const toastState = atom<[] | Toast[]>({
  key: "toastState",
  default: [],
});

export { toastState };
