import type { Languages } from "@/types";

import { PATH } from "constants/index";

// NOTE: 번역이 없지만 공용 컴포넌트를 사용하기 위해 Languages 단언
export const HISTORY_PATH: {
  [key: string]: { name: Languages; path?: string };
} = {
  device: { name: "KOKKOK IoT" as Languages, path: PATH.DEVICE },
  deviceDetail: {
    name: "Detail information" as Languages,
    path: PATH.DEVICE,
  },
};
