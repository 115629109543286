import type { Languages } from "@/types";

export const COMMON_ERROR_MSG = {
  EMAIL_EXISTS: "EMAIL_EXISTS",
  EMAIL_NOT_FOUND: "EMAIL_NOT_FOUND",
  DUPLICATED_SIGNIN_DETECTED: "DUPLICATED_SIGNIN_DETECTED", // TODO: move admin, iot 서버 반영 및 웹에 배포 완료 후 삭제 필요
  DUPLICATE_SIGNIN_DETECTED: "DUPLICATE_SIGNIN_DETECTED",
  INVALID_ACCESS_TOKEN: "INVALID_ACCESS_TOKEN",
  INVALID_REFRESH_TOKEN: "INVALID_REFRESH_TOKEN",
  ALREADY_CANCELED: "ALREADY_CANCELED",
  SIGNIN_FAILED: "Incorrect email or password.",
  BAD_REQUEST: "Incorrect email or password.",
  EMAIL: "Please enter email address.",
  EMAIL_VALID: "Please enter a valid email address.",
  FIELD: "Please fill out this field.",
  MOBILE_VALID: "Please enter a valid mobile number.",
  PASSWORD: "Please enter password.",
  PASSWORD_CONFIRM: "Password matches",
  CANNOT_UPDATE_PASSWORD: "Is not a current password",
  PASSWORD_LENGTH: "8-20 characters",
  PASSWORD_TYPE: "At least 2 types (A-Z, a-z, numbers, symbols)",
  VERIFY_CODE_INCORRECT: "The verification code is incorrect.",
} as const;

export const ADMIN_ERROR_MSG = {
  ...COMMON_ERROR_MSG,
  ALREADY_DISPATCHED: "ALREADY_DISPATCHED",
  BATTERY_NOT_FOUND: "BATTERY_NOT_FOUND",
  CANNOT_DISPATCH_DRIVER: "CANNOT_DISPATCH_DRIVER",
  CANNOT_UPDATE_VEHICLE: "CANNOT_UPDATE_VEHICLE",
  DRIVER_NOT_FOUND: "DRIVER_NOT_FOUND",
  HELP_ALREADY_ANSWERED: "HELP_ALREADY_ANSWERED",
  PAYMENT_FAILED: "PAYMENT_FAILED",
  TRANSPORT_NOT_FOUND: "TRANSPORT_NOT_FOUND",
  BANK_PHONE_ALREADY_USED: "This IB Cool Wallet number is already registered.",
  BANK_PHONE_VALID: "Please enter a valid IB Cool Wallet number.",
  CANNOT_UPDATE_STATUS: "Status cannot be changed.",
  COLOR_HEX_CODE_INCORRECT: "Please enter a valid hex code.",
  COLOR_HEX_CODE_LENGTH: "Hex color code is 6 characters.",
  COUPON_CODE_ALREADY_SELECTED:
    "The coupon code that you inserted has been already occupied.",
  COUPON_CODE_DUPLICATE: "This coupon code already registered.",
  COUPON_CODE_LENGTH: "Set the coupon code in 10 characters.",
  COUPON_MINIMUM_PAYMENT: "Please enter more than ₭ 1,000",
  DIGIT_NUM: "Please enter the 10~15 digit number.",
  DRIVER_CHARGE_POINT_LIMIT: "Please enter 1 ~ 100,000,000,000 P.",
  DRIVER_GROUP_REQUIRED: "Please select group.",
  DRIVER_TYPE_REQUIRED: "Please select driver type.",
  END_TIME_BEFORE_THAN_START_TIME: "Start date must be prior to the end date.",
  FILE_UPLOAD: "Please upload image.",
  INFO: "Please fill in the required information.",
  MOBILE_ALREADY: "This mobile number is already registered.",
  ORDER_NUMBER: "Please check the order number format.",
  PASSWORD_INCORRECT: "Is not a current password.",
  REQUIRED_DATE: "Please select the date.",
  SELECT_TIME_BEFORE_THAN_ORIGIN_END_TIME:
    "Date edited must be behind the date selected.",
  TIME_VALID: "Please check the time format.",
  URL_INCORRECT: "Please enter a valid URL.",
  VAILED_IB_COOL_NUMBER: "Please enter a vaild IB Cool Wallet number",
  VEHICLE_REGNO_ALREADY_EXISTS:
    "The plate number that you entered has been already registered.",
  VEHICLE_REQUIRED: "Please select vehicle.",
  VEHICLE_SERVICE_FIELD: "Please select more than 1.",
  VEHICLE_VIN_ALREADY_EXISTS:
    "The vehicle ID number that you entered has been already registered.",
  VERIFY_CODE_EXPIRED: "The verification code has expired. Please try again.",
  VERSION_INCORRECT: "Please enter the version in valid format. (0.0.0)",

  // MOBILE: "Enter the mobile number", // NOTE: client 에서는 사용 안 하는데 구글 번역 시트에는 있음 확인 필요
  // CHARGING_POINT: "The requested amount and the charging amount are different.", // NOTE: 구글 번역 시트에 있음. client에서 사용 안 함 확인 필요
} as const;

export const LOGISTICS_ERROR_MSG = {
  ...COMMON_ERROR_MSG,
  ALREADY_EXISTS_ID: "This login ID is already registered.",
  ALREADY_EXISTS_PLATE_NUMBER:
    "The plate number that you entered has been already registered.",
} as const;

export const IOT_ERROR_MSG = { ...COMMON_ERROR_MSG } as const;
