import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import adminEn from "./files/admin/admin-en.json";
import adminLo from "./files/admin/admin-lo.json";
import logisticsEn from "./files/logistics/logistics-en.json";
import logisticsLo from "./files/logistics/logistics-lo.json";

export const fallbackLng = "EN";

export const adminResources = {
  en: { translation: adminEn },
  lo: { translation: adminLo },
} as const;

export const logisticsResources = {
  en: { translation: logisticsEn },
  lo: { translation: logisticsLo },
} as const;

const combinedEn = { ...adminEn, ...logisticsEn };
const combinedLo = { ...adminLo, ...logisticsLo };

export const resources = {
  en: { translation: combinedEn },
  lo: { translation: combinedLo },
} as const;

i18n.use(initReactI18next).init({ resources, fallbackLng: "en" });

export default i18n;
