/** @jsxImportSource @emotion/react */ // NOTE: 공용 컴포넌트에서 className으로 수정하려고 하면 필요
import React, { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

import { Button } from "@/components";
import { isInitFilterAtom } from "@/stores";
import { comma, formatICTDateTime, formatLocalDateTime } from "@/utils";
import { DownloadIcon, ResetIcon } from "@/assets";
import type { Languages } from "@/types";

import * as S from "./DataSection.styled";

interface DataSectionProps {
  className?: string;
  children: React.ReactNode;
  totalData: number;
  activeButtons?: React.ReactNode;
  dataUpdatedAt?: number;
  title?: Languages;
  isLocalTime?: boolean;
  refetch: () => void;
  remove: () => void;
}

interface ActiveWrapperProps {
  className?: string;
  activeButton: React.ReactNode;
  isActive?: boolean;
  isDisabled?: boolean;
  handleConfirm?: (e: React.MouseEvent) => void;
  handleUnActiveCheckTable?: () => void;
}

interface ExcelDownloadButtonProps {
  className?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  handleDownload: () => void;
}

export interface ActiveButtonProps {
  className?: string;
  label: Languages;
  variant?: "primary" | "secondary";
  isDisabled?: boolean;
  handleActiveButtonClick: () => void;
}

const DataSection = ({
  children,
  className,
  totalData,
  activeButtons,
  dataUpdatedAt,
  title = "List",
  isLocalTime = false,
  refetch,
  remove,
}: DataSectionProps) => {
  const { t } = useTranslation();

  const isInitFilter = useRecoilValue(isInitFilterAtom);

  const handleRefetch = useCallback(() => {
    remove && remove();
    refetch && refetch();
  }, [remove, refetch]);

  return (
    <section className={className}>
      <S.Header>
        <S.LeftContent>
          <h2>
            {t(title)}&#32;(
            {isInitFilter ? 0 : totalData ? comma(totalData) : 0})
          </h2>
          {!isInitFilter && (
            <S.Refetch>
              <span>{t("Latest updated")}:</span>
              <time>
                {dataUpdatedAt
                  ? isLocalTime
                    ? formatLocalDateTime(dataUpdatedAt, "DD/MM/YYYY, HH:mm")
                    : formatICTDateTime(dataUpdatedAt)
                  : "-"}
              </time>
              {dataUpdatedAt && (
                <S.RefetchButton type="button" onClick={handleRefetch}>
                  <ResetIcon css={S.resetIcon} />
                </S.RefetchButton>
              )}
            </S.Refetch>
          )}
        </S.LeftContent>
        {activeButtons}
      </S.Header>
      {children}
    </section>
  );
};

DataSection.ActiveWrapper = function ActiveWrapper({
  className,
  activeButton,
  isActive,
  isDisabled,
  handleConfirm,
  handleUnActiveCheckTable,
}: ActiveWrapperProps) {
  return (
    <S.ActiveWrapper className={className}>
      {isActive ? (
        <>
          <Button
            variant="secondary"
            label="Cancel"
            handleButtonClick={handleUnActiveCheckTable}
          />
          <Button
            isDisabled={!isDisabled}
            variant="primary"
            label="Confirm"
            handleButtonClick={handleConfirm}
          />
        </>
      ) : (
        <>{activeButton}</>
      )}
    </S.ActiveWrapper>
  );
};

DataSection.ExcelDownloadButton = function ExcelDownloadButton({
  handleDownload,
  isLoading,
  isDisabled,
}: ExcelDownloadButtonProps) {
  return (
    <Button
      css={S.downloadButton}
      icon={<DownloadIcon />}
      isLoading={isLoading}
      isDisabled={isDisabled}
      variant="secondary"
      label="Export"
      handleButtonClick={handleDownload}
    />
  );
};

DataSection.ActiveButton = function CheckTableActiveButton({
  className,
  label,
  variant = "secondary",
  isDisabled = false,
  handleActiveButtonClick,
}: ActiveButtonProps) {
  return (
    <Button
      className={className}
      variant={variant}
      label={label}
      isDisabled={isDisabled}
      handleButtonClick={handleActiveButtonClick}
    />
  );
};

export default DataSection;
