import { useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { isEmpty } from "lodash-es";

import { isInitFilterAtom } from "@/stores";
import type { FilterLabels } from "@/types";

const useFilterSearchLabel = (
  filterLabels: FilterLabels,
  handleLocalValueChange: (queryKey: string, value: string | string[]) => void,
  handleLocalValueReset: () => void,
  handleTotalValueChange: (queryKey: string, searchResult: string[]) => void,
  handleTotalValueReset: () => void,
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isInitFilter, setIsInitFilter] = useRecoilState(isInitFilterAtom);

  const hasLabel = Object.values(filterLabels).some(
    ({ searches }) => searches?.length !== 0,
  );

  const isResetButtonDisabled =
    isEmpty(filterLabels) ||
    Object.entries(filterLabels).every(
      ([key, { searches }]) => searches?.length === 0 && !searchParams.get(key),
    );

  const isApplyButtonDisabled =
    isEmpty(filterLabels) ||
    Object.values(filterLabels).some(
      ({ searches, options }) => options?.isRequired && searches?.length === 0,
    );

  const handleDeleteSearchLabel = (queryKey: string) => (): void => {
    handleLocalValueChange(queryKey, "");
    handleTotalValueChange(queryKey, []);
  };

  const handleResetSearchLabel = (): void => {
    handleLocalValueReset();
    handleTotalValueReset();
  };

  const handleSearchLabelApply = (): void => {
    const searchFilter = Object.entries(filterLabels).reduce(
      (acc, [key, { searches }]) => {
        if (!searches.length) return acc;

        return { ...acc, [key]: searches[0] };
      },
      {},
    );

    setSearchParams(
      {
        ...(searchParams.get("tab") && { tab: searchParams.get("tab")! }),
        ...searchFilter,
        page: "1",
      },
      { replace: true },
    );

    isInitFilter && setIsInitFilter(false);
  };

  const getSearchValues = (
    queryKey: string,
    searchResult: string | string[],
  ): string | string[] => {
    /*
      NOTE: queryKey가 'date' 형태일 경우 '기간' 형태로 보여주어야 함
            * 'date' 형태의 queryKey 종류는 하기와 같이 dateQueries 배열로 정의
    */
    const dateQueries = ["date", "pickup", "updated", "created", "completed"];

    if (dateQueries.includes(queryKey) && typeof searchResult === "object") {
      return `${searchResult[0]} ~ ${searchResult[1] ?? searchResult[0]}`;
    }

    return searchResult;
  };

  const handleSetFilterLabel = (
    queryKey: string,
    searchResult: string | string[],
  ): void => {
    const searches = getSearchValues(queryKey, searchResult);

    handleTotalValueChange(
      queryKey,
      searches ? (typeof searches === "object" ? searches : [searches]) : [],
    );
  };

  return {
    isResetButtonDisabled,
    isApplyButtonDisabled,
    hasLabel,
    handleSetFilterLabel,
    handleSearchLabelApply,
    handleDeleteSearchLabel,
    handleResetSearchLabel,
  };
};

export default useFilterSearchLabel;
