import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Align = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const connectIconStatus = (isConnected: boolean) => (theme: Theme) =>
  css`
    width: 16px;
    height: 16px;

    & > path {
      fill: ${isConnected ? theme.color.green_20 : theme.color.gray_40};
    }
  `;
