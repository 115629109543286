import React from "react";

import { QueryPagination } from "@/components";
import { useFilterParams } from "@/hooks";

import { useGetIoTDevice } from "services";
import { DEVICE_FILTER_QUERIES } from "assets";
import type { GetIoTDeviceQueryModel } from "types";

const DevicePagination = () => {
  const { filters } = useFilterParams<GetIoTDeviceQueryModel["query"]>(
    DEVICE_FILTER_QUERIES,
  );

  const { data } = useGetIoTDevice({
    query: filters,
  });

  return <QueryPagination pageInfo={data?.pageInfo} />;
};

export default DevicePagination;
