import React, { Suspense } from "react";

import {
  DataSectionSkeleton,
  PaginationSkeleton,
  TableSkeleton,
} from "@/components";

import { PageLayout } from "components";
import {
  DEVICE_LAYOUT,
  DEVICE_TABLE_GRID_COLUMNS,
  DEVICE_TABLE_HEADER,
} from "assets";
import { DevicePagination, DeviceSection } from "./containers";

const Device = () => {
  return (
    <PageLayout layoutStaticData={DEVICE_LAYOUT}>
      <Suspense
        fallback={
          <DataSectionSkeleton>
            <TableSkeleton
              gridTemplateColumns={DEVICE_TABLE_GRID_COLUMNS}
              columnTable={DEVICE_TABLE_HEADER}
            />
          </DataSectionSkeleton>
        }
      >
        <DeviceSection />
      </Suspense>
      <Suspense fallback={<PaginationSkeleton />}>
        <DevicePagination />
      </Suspense>
    </PageLayout>
  );
};

export default Device;
