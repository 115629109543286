import { calcTableWidth } from "utils";

export const DEVICE_TABLE_GRID_COLUMNS = calcTableWidth([
  "300px",
  "176px",
  "300px",
  "138px",
  "124px",
  "98px",
  "90px",
]);

export const HAS_DELETE_DEVICE_TABLE_GRID_COLUMNS = calcTableWidth([
  "300px",
  "176px",
  "300px",
  "138px",
  "124px",
  "98px",
  "90px",
  "44px",
]);

export const DEVICE_DETAIL_TABLE_GRID_COLUMNS = calcTableWidth([
  ["120px", "180px"],
  ["170px", "420px"],
  ["94px", "103px"],
  ["102px", "120px"],
  ["95px", "102px"],
  ["105px", "120px"],
  ["131px", "244px"],
  "230px",
  ["106px", "120px"],
  ["150px", "180px"],
  "102px",
]);
