export const size = {
  HEADER_HEIGHT: "56px",
  NAV_WIDTH: "64px",
  MAIN_PADDING_TOP: "36px",
  MAIN_PADDING_BOTTOM: "40px",
  MAIN_PADDING_SIDE: "40px",
  MAIN_PADDING: "36px 40px 40px", // TODO: 추후 전체 페이지에 적용할 수 있게 수정 필요함
  MAIN_PADDING_DELETE: "-36px -40px -40px", // TODO: 추후 전체 페이지에 적용할 수 있게 수정 필요함
  MOVE_MONITORING_AUTO_REFRESH_TOGGLE_BUTTON_WIDTH: "85px",
  MOVE_MONITORING_LIST_WIDTH: "390px",
  SECTION_MIN_WIDTH: "1296px",
  SECTION_MAX_WIDTH: "1660px",
  TABLE_CELL_HEIGHT: "40px",
  TABLE_HEADER_HEIGHT: "52px",
  SCROLL_BAR_WIDTH: "8px",
} as const;

export type SizeType = typeof size;
