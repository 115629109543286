import { useEffect } from "react";

import { useGoogleMap } from "@/context";
import { LAT_LNG } from "@/constants";
import type { Coord } from "@/types";

const useDeviceRecentLocationMarker = (recentLatLng: Coord | undefined) => {
  const { googleMap } = useGoogleMap();

  useEffect(() => {
    if (!googleMap) return;

    // NOTE: 위, 경도를 중심으로 지도의 가운데로 이동
    googleMap.setOptions({
      center: {
        lat: recentLatLng ? recentLatLng.y : LAT_LNG.LSM_OFFICE.lat,
        lng: recentLatLng ? recentLatLng.x : LAT_LNG.LSM_OFFICE.lng,
      },
      zoom: 17,
    });

    if (!recentLatLng) return;

    // NOTE: 위, 경도 값을 기반으로 마커 생성
    const marker = new google.maps.Marker({
      map: googleMap,
      position: { lat: recentLatLng.y, lng: recentLatLng.x },
    });

    return () => {
      marker.setMap(null);
    };
  }, [recentLatLng, googleMap]);
};

export default useDeviceRecentLocationMarker;
