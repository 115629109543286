import React from "react";
import { useTranslation } from "react-i18next";

import { LoadingSpinner } from "@/components";
import type { Languages } from "@/types";

import * as S from "./GhostButton.styled";

export type ButtonVariant =
  | "alert"
  | "alert_blue"
  | "alert_red"
  | "ghost"
  | "ghost_blue"
  | "ghost_red";

interface GhostButtonProps {
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  type?: "button" | "submit" | "reset";
  variant: ButtonVariant;
  label: Languages;
  icon?: {
    component: React.ReactNode;
    position: "left" | "right";
  };
  handleButtonClick?: (e: React.MouseEvent) => void;
}

const GhostButton = ({
  className,
  isDisabled,
  isLoading = false,
  icon,
  label,
  type = "button",
  variant,
  handleButtonClick,
}: GhostButtonProps) => {
  const { t } = useTranslation();

  return (
    <S.GhostButton
      className={className}
      isLoading={isLoading}
      disabled={isDisabled}
      type={type}
      variant={variant}
      onClick={handleButtonClick}
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {icon?.position === "left" && icon.component}
          {t(label)}
          {icon?.position === "right" && icon.component}
        </>
      )}
    </S.GhostButton>
  );
};

export default GhostButton;
