import type { Languages } from "@/types";

// NOTE: 번역이 필요 없기 때문에 Langauges로 단언
export const DEVICE_TABLE_HEADER = {
  id: "IoT ID" as Languages,
  vin: "VIN Number" as Languages,
  imei: "IMEI" as Languages,
  deviceIp: "IP" as Languages,
  certificatesLink: "Certificates Link" as Languages,
  registered: "Registration" as Languages,
  status: "Connect" as Languages,
} as const;

export const HAS_DELETE_DEVICE_TABLE_HEADER = {
  id: "IoT ID" as Languages,
  vin: "VIN Number" as Languages,
  imei: "IMEI" as Languages,
  deviceIp: "IP" as Languages,
  certificatesLink: "Certificates Link" as Languages,
  registered: "Registration" as Languages,
  status: "Connect" as Languages,
  delete: "" as Languages,
} as const;

// NOTE: 번역이 필요 없기 때문에 Langauges로 단언
export const DEVICE_DETAIL_TABLE_HEADER = {
  vehicleStatus: "vehicleStatus" as Languages,
  vehicleId: "vehicleId" as Languages,
  connection: "connection" as Languages,
  batteryState: "batteryState" as Languages,
  latitude: "latitude" as Languages,
  longitude: "longitude" as Languages,
  eventTime: "eventTime" as Languages,
  swVersion: "sw_version" as Languages,
  vehicleSpeed: "vehicleSpeed" as Languages,
  batteryTemperature: "batteryTemperature" as Languages,
  batteryId: "batteryId" as Languages,
} as const;
