/** @jsxImportSource @emotion/react */ // NOTE: 공용 컴포넌트에서 className으로 수정하려고 하면 필요
import React from "react";
import { useTranslation } from "react-i18next";

import { NoneSearchIcon, NoneSelectIcon } from "@/assets";
import type { Languages } from "@/types";

import * as S from "./NoResult.styled";

interface NoResultProps {
  className?: string;
  contents: Languages[];
  type?: "search" | "select";
}

const NoResult = ({ className, contents, type = "search" }: NoResultProps) => {
  const { t } = useTranslation();

  const icon = {
    search: <NoneSearchIcon />,
    select: <NoneSelectIcon />,
  };

  return (
    <S.Wrapper className={className}>
      {icon[type]}
      <S.ContentWrapper>
        {contents.map((content) => {
          return <S.Content key={content}>{t(content)}</S.Content>;
        })}
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default NoResult;
