import type { Languages } from "@/types";

export const COMMON_TOAST_MSG = {
  SUCCESS: {
    PASSWORD_CHANGE: {
      type: "success",
      content: "Password has been changed.",
    },
    DISPATCH_CANCEL: {
      type: "success",
      content: "Dispatch has been canceled.",
    },
  },
  WARNING: {
    FAIL_FILE_UPLOAD_5MB: {
      type: "warning",
      content: "The file cannot exceed more than 5MB.",
    },
    ROUTE_DISTANCE_EXCEED: {
      type: "warning",
      content: "The route cannot be displayed because it exceeds 2,000 km.",
    },
    CANNOT_FIND_ROUTABLE_POINT: {
      type: "warning",
      content:
        "A problem occurred while calculating the route, so the route cannot be displayed.",
    },
  },
} as const;

export const ADMIN_TOAST_MSG = {
  SUCCESS: {
    ...COMMON_TOAST_MSG.SUCCESS,
    ACCOUNT_UPDATE: {
      type: "success",
      content: "The account information has been changed.",
    },
    ADMIN_CREATE: {
      type: "success",
      content: "The administrator account has been added.",
    },
    ADMIN_DELETE: {
      type: "success",
      content: "The administrator account has been deleted.",
    },
    ADMIN_UPDATE: {
      type: "success",
      content: "The administrator account information has been changed.",
    },
    ADVERTISEMENT_ADD: {
      type: "success",
      content: "The advertisement has been added successfully.",
    },
    ADVERTISEMENT_EDIT: {
      type: "success",
      content: "The advertisement has been edited successfully.",
    },
    BANNER_CREATE: {
      type: "success",
      content: "The banner has been added.",
    },
    BANNER_DELETED: {
      type: "success",
      content: "The banner has been deleted.",
    },
    BANNER_INFO_CHANGED: {
      type: "success",
      content: "The banner information has been changed.",
    },
    CHANGE_DRVIER_STATUS_OF_TRAINING: {
      type: "success",
      content: "Status has been changed.",
    },
    CLIENT_DELETE: {
      type: "success",
      content: "Client account has been deleted.",
    },
    COPY_DRIVER_MOBILE: {
      type: "success",
      content: "Copied the mobile number.",
    },
    COUPON_CREATE_COMPLETE: {
      type: "success",
      content: "The coupon has been added.",
    },
    COUPON_UPDATE_COMPLETE: {
      type: "success",
      content: "The coupon information has been changed.",
    },
    CREATE_ALARM: {
      type: "success",
      content: "Your message has been sent successfully.",
    },
    DISPATCH_COMPLETE: {
      type: "success",
      content: "The dispatch has been completed.",
    },
    DRIVER_APPROVE: {
      type: "success",
      content: "Verification completed.",
    },
    DRIVER_DELETE: {
      type: "success",
      content: "Driver account has been deleted.",
    },
    DRIVER_SCHEDULE_CHANGE: {
      type: "success",
      content: "The schedule has been changed.",
    },
    DRIVER_TYPE_CHANGE: {
      type: "success",
      content: "The driver type has been changed.",
    },
    DRIVER_VEHICLE_MATCH: {
      type: "success",
      content: "The vehicle information has been changed.",
    },
    DRIVER_VEHICLE_PLATE_NUMBER: {
      type: "success",
      content: "Plate number has been changed.",
    },
    DRIVER_VEHICLE_SERVICE: {
      type: "success",
      content: "Service type has been changed.",
    },
    DRIVER_VEHICLE_STATUS: {
      type: "success",
      content: "Status type has been changed.",
    },
    EARNINGS_CHANGE_COMPLETE: {
      type: "success",
      content: "Hero earnings has been changed.",
    },
    MOBILE_CHANGE: {
      type: "success",
      content: "Mobile number has been changed.",
    },
    MOVE_DISPATCH_COMPLETE: {
      type: "success",
      content: "Dispatch has been completed.",
    },
    NOTE_DELETE: {
      type: "success",
      content: "The note has been deleted.",
    },
    NOTICE_CREATE: {
      type: "success",
      content: "The notice has been added.",
    },
    NOTICE_DELETED: {
      type: "success",
      content: "The notice has been deleted.",
    },
    NOTICE_UPDATE: {
      type: "success",
      content: "The notice has been changed.",
    },
    PINNED: {
      type: "success",
      content: "The notice has been pinned.",
    },
    POINT_CHARGING_COMPLETE: {
      type: "success",
      content: "The point charge has been completed.",
    },
    POINT_CHARGING_COMPLETED: {
      type: "success",
      content: "Points charging completed.",
    },
    POINTS_REJECT_COMPLETE: {
      type: "success",
      content: "The point charge request has been rejected.",
    },
    REPLY_CREATE: {
      type: "success",
      content: "The reply has been added.",
    },
    STATUS_CHANGE: {
      type: "success",
      content: "Status has been changed.",
    },
    UNPINNED: {
      type: "success",
      content: "The notice has been unpinned.",
    },
    UPDATE_COUPON_PRIVATE_USER_FILE_UPLOAD: {
      type: "success",
      content: "The file has been changed successfully.",
    },
    UPDATE_DRIVER_BANK_PHONE: {
      type: "success",
      content: "IB Cool Wallet number has been changed.",
    },
    UPDATE_DRIVER_CORE_TIME: {
      type: "success",
      content: "Core time has been changed.",
    },
    UPDATE_DRIVER_HERO_TYPE: {
      type: "success",
      content: "Hero type has been changed.",
    },
    UPDATE_DRIVER_NAME: {
      type: "success",
      content: "The name has been revised successfully.",
    },
    UPDATE_PAYMENT_GATEWAY: {
      type: "success",
      content: "The payment gateway has been changed successfully.",
    },
    UPDATE_VERSION: {
      type: "success",
      content: "The update version has been edited successfully.",
    },
    VEHICLE_CREATE: {
      type: "success",
      content: "The vehicle has been added successfully.",
    },
    WITHDRAWAL_COMPLETE: {
      type: "success",
      content: "The withdrawal has been completed.",
    },
    WITHDRAWAL_REJECT_COMPLETE: {
      type: "success",
      content: "The withdrawal request has been rejected.",
    },
  },
  WARNING: {
    ...COMMON_TOAST_MSG.WARNING,
    ALREADY_CANCELED: {
      type: "warning",
      content: "The order has been canceled.",
    },
    ALREADY_DISPATCHED: {
      type: "warning",
      content: "The dispatch has already been accepted.",
    },
    BANNER_DELETE_FAIL: {
      type: "warning",
      content: "If the banner status is 'ON', it cannot be deleted.",
    },
    BANNER_MODIFY_FAIL: {
      type: "warning",
      content: "If the banner status is 'ON', it cannot be modified.",
    },
    CANNOT_DISPATCH_DRIVER: {
      type: "warning",
      content: "There is an ongoing order or a payment has not been completed.",
    },
    CANNOT_FIND_MONITORING_DRIVER_LOCATION: {
      type: "warning",
      content:
        "Location of this vehicle is currently out of service. Please try again later.",
    },
    COPY_DRIVER_MOBILE_FAIL: {
      type: "warning",
      content: "Failed copying mobile number, please try again later.",
    },
    DRIVER_NOT_FOUND: {
      type: "warning",
      content: "There are no vehicles nearby, please select another order.",
    },
    DRIVER_TYPE_CHANGE: {
      type: "warning",
      content:
        "If there is a matched vehicle, the driver type cannot be changed.",
    },
    DRIVER_VEHICLE_NOT_MATCH: {
      type: "warning",
      content: "Vehicle status cannot be changed.",
    },
    DRIVER_VEHICLE_STATUS: {
      type: "warning",
      content: "Unmatching the vehicle and driver, then try again.",
    },
    FAIL_ALARM: {
      type: "warning",
      content: "Failed to send the message, please try again later.",
    },
    FAIL_FILE_UPLOAD_1MB: {
      type: "warning",
      content: "The file cannot exceed more than 1MB.",
    },
    FILTER_MAX_NUM: {
      type: "warning",
      content: "Each filter can only be applied up to 5.",
    },
    HUB_PROCESS_FAIL: {
      type: "warning",
      content:
        "Processing is possible at once only if the departure hub and arrival hub are the same.",
    },
    INQUIRY_ANSWER_ALREADY_EXIST: {
      type: "warning",
      content: "The inquiry has already been answered.",
    },
    MOBILE_ALREADY: {
      type: "warning",
      content: "This mobile number is already registered.",
    },
    MOVE_MANAGEMENT_ORDER_PAYMENT_STATUS_FAILED: {
      type: "warning",
      content: "Please change payment status",
    },
    NOT_SUPPORT_FILE_FORMAT: {
      type: "warning",
      content: "File format is not supported.",
    },
    PIN_FAIL: {
      type: "warning",
      content: "You can pin up to 5 posts.",
    },
    POINT_INSUFFICIENT_DISPATCH_FAIL: {
      type: "warning",
      content:
        "We are unable to proceed with this shipment due to insufficient driver points.",
    },
    ROUTE_OVERLAP: {
      type: "warning",
      content: "The route is already set.",
    },
    ROUTE_SET_FAIL: {
      type: "warning",
      content: "The route is not set.",
    },
    SHIPMENT_ALREADY_ONGOING: {
      type: "warning",
      content: "There is an ongoing order or a payment has not been completed.",
    },
    STATUS_CHANGE_FAIL: {
      type: "warning",
      content: "Status cannot be changed.",
    },
    VEHICLE_IDNUMBER_CONDITION_FAIL: {
      type: "warning",
      content: "Vehicle ID number is 17 characters.",
    },
  },
} as const;

export const LOGISTICS_TOAST_MSG = {
  SUCCESS: {
    ...COMMON_TOAST_MSG.SUCCESS,

    CREATE_PROPERTY_VEHICLE: {
      type: "success",
      content: "The vehicle has been added successfully.",
    },
    DELETE_PROPERTY_DRIVER: {
      type: "success",
      content: "Driver has been deleted.",
    },
    DELETE_PROPERTY_VEHICLE: {
      type: "success",
      content: "Vehicle has been deleted.",
    },
    CREATE_PROPERTY_DRIVER: {
      type: "success",
      content: "Driver has been added.",
    },
    EDIT_PROPERTY_DRIVER: {
      type: "success",
      content: "Driver information has been changed.",
    },
    EDIT_PROPERTY_VEHICLE: {
      type: "success",
      content: "The vehicle information has been changed.",
    },
    EDIT_PROPERTY_VEHICLE_STATUS: {
      type: "success",
      content: "Status has been changed.",
    },
    INVOICE_DISPATCH_COMPLETE: {
      type: "success",
      content: "Dispatch has been completed.",
    },
    UPDATE_DELIVERY_ROUTE: {
      type: "success",
      content: "The routes has been edited successfully.",
    },
    UPDATE_DISPATCH_DRIVER: {
      type: "success",
      content: "Driver has been changed.",
    },
    UPDATE_DISPATCH_VEHICLE: {
      type: "success",
      content: "Truck has been changed.",
    },
    UPDATE_INVOICE_STATUS: {
      type: "success",
      content: "Status has been changed.",
    },
  },
  WARNING: {
    ...COMMON_TOAST_MSG.WARNING,
    EDIT_PROPERTY_DRIVER_VEHICLE_EXIST_SCHEDULE: {
      type: "warning",
      content:
        "Unable to process your request due to shipping schedule exists.",
    },
    ROUTE_SETTING_NOT_AVAILABLE: {
      type: "warning",
      content: "The route setting is not available.",
    },
  },
} as const;
