import React from "react";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";

import { LoginForm } from "@/components";
import { makeCryptoPassword } from "@/utils";
import { IOT_ERROR_MSG } from "@/constants";
import type { LoginFormType } from "@/types";

import { auth } from "contexts";
import { useLogin } from "services";
import { LogoLoginIcon } from "assets";
import { PATH } from "constants/index";

const Login = () => {
  const navigate = useNavigate();

  const formMethod = useForm<LoginFormType>();

  const { mutate: loginMutate } = useLogin();

  const handleLogin = (data: LoginFormType): void => {
    const req = {
      email: data.email,
      password: makeCryptoPassword(data.password),
    } as const;

    loginMutate(req, {
      onSuccess: (data) => {
        auth.signIn(data);
        navigate(PATH.DEVICE);
      },
      onError: (error) => {
        switch (error.response?.data.message) {
          case "SIGNIN_FAILED":
          case "BAD_REQUEST":
            formMethod.setError("email", {
              message: IOT_ERROR_MSG.SIGNIN_FAILED,
            });
        }
      },
    });
  };

  return (
    <FormProvider {...formMethod}>
      <LoginForm logoIcon={<LogoLoginIcon />} handleLogin={handleLogin} />
    </FormProvider>
  );
};

export default Login;
