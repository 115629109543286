import { useMutation } from "@tanstack/react-query";

import { loginAPI, logoutAPI } from "apis";
import type { IotApiError, LoginQueryModel, LoginServerModel } from "types";

export const useLogin = () => {
  return useMutation<LoginServerModel, IotApiError, LoginQueryModel>({
    mutationFn: (req) => loginAPI(req),
  });
};

export const useLogout = () =>
  useMutation({
    mutationFn: () => logoutAPI(),
  });
