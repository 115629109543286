import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const PageLayout = styled.section`
  ${({ theme }) => css`
    min-width: ${theme.size.SECTION_MIN_WIDTH};
    width: ${`calc(100vw - ${theme.size.NAV_WIDTH} - ${theme.size.MAIN_PADDING_SIDE} * 2)`};
    max-width: ${theme.size.SECTION_MAX_WIDTH};
    margin: 0 auto;
  `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

export const Heading = styled.h1`
  ${({ theme }) => css`
    ${theme.font.bold_24};
    color: ${theme.color.black};
  `}
`;
