import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

import { isInitFilterAtom } from "@/stores";
import { Languages } from "@/types";

import * as S from "./History.styled";

interface HistoryProps {
  histories: readonly string[];
  historyPath: {
    [key: string]: {
      name: Languages;
      path?: string;
    };
  };
}

const History = ({ histories, historyPath }: HistoryProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isInitFilter, setIsInitFilter] = useRecoilState(isInitFilterAtom);

  const handleHistoryClick = (history: string, i: number) => (): void => {
    const isLastHistory = histories.length - 1 === i;
    if (isLastHistory) return;

    const path = historyPath[history].path;
    if (!path) return;

    navigate(path);

    if (isInitFilter) return;
    setIsInitFilter(true);
  };

  return (
    <S.History>
      {histories.map((history, i) => (
        <S.HistoryItem key={history + i}>
          <S.HistoryButton
            type="button"
            hasPath={!!historyPath[history]?.path}
            tabIndex={i === histories.length - 1 ? -1 : 0}
            onClick={handleHistoryClick(history, i)}
          >
            {t(historyPath[history].name)}
          </S.HistoryButton>
        </S.HistoryItem>
      ))}
    </S.History>
  );
};

export default History;
