import React from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";

import { useAuth } from "contexts";
import { PublicLayout } from "components";
import { PATH } from "constants/index";

const PublicRoute = () => {
  const location = useLocation();

  const { user, initializing } = useAuth();

  if (initializing) return null;

  return user ? (
    <Navigate to={PATH.DEVICE} state={{ from: location }} />
  ) : (
    <PublicLayout>
      <Outlet />
    </PublicLayout>
  );
};

export default PublicRoute;
