import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Main = styled.main`
  ${({ theme }) => css`
    position: relative;
    min-width: max-content;
    width: calc(100% - ${theme.size.NAV_WIDTH});
    min-height: calc(100vh - ${theme.size.HEADER_HEIGHT});
    margin: ${theme.size.HEADER_HEIGHT} 0 0 ${theme.size.NAV_WIDTH}; // TODO: 추후 전체 페이지에 적용할 수 있게 수정 필요함
    padding: ${theme.size
      .MAIN_PADDING}; // TODO: 추후 전체 페이지에 적용할 수 있게 수정 필요함
  `}
`;
