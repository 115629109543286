import { css, Theme } from "@emotion/react";

export const downloadButton = (theme: Theme) => css`
  z-index: ${theme.zIndex.TABLE_SELECT_ROW};
`;

export const deleteButtonWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

export const deleteButton = (theme: Theme) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: ${theme.zIndex.TABLE_SELECT_ROW};

  :hover {
    background-color: ${theme.color.gray_20};
  }
`;

export const trashIcon = (theme: Theme) => css`
  & > path {
    fill: ${theme.color.gray_50};
  }
`;
