import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const History = styled.ul`
  display: flex;
  column-gap: 16px;
  margin-bottom: 8px;
`;

export const HistoryItem = styled.li`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;

    &:last-of-type > button {
      color: ${theme.color.gray_70};
    }

    &:not(:last-of-type)::after {
      content: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.65 5.478L5.551 3.437L0.65 1.11V0.0959995L6.773 3.151V3.801L0.65 6.492V5.478Z' fill='%23A2ABB0'/%3E%3C/svg%3E%0A");
      position: absolute;
      right: -12px;
      width: 8px;
      height: 8px;
    }
  `}
`;

interface HistoryButtonProps {
  hasPath?: boolean;
}

export const HistoryButton = styled.button<HistoryButtonProps>`
  ${({ theme, hasPath }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_50};
    cursor: ${hasPath ? "pointer" : "default"};

    &:hover {
      text-decoration: ${hasPath && "underline"};
      text-decoration-skip-ink: ${hasPath && "none"};
    }
  `}
`;
