import React from "react";

import { History } from "@/components";

import { HISTORY_PATH } from "assets";
import * as S from "./PageLayout.styled";

interface PageLayoutProps {
  className?: string;
  children: React.ReactNode;
  button?: React.ReactNode;
  layoutStaticData: {
    heading: string;
    histories?: readonly string[];
  };
}

const PageLayout = ({
  className,
  children,
  button,
  layoutStaticData,
}: PageLayoutProps) => {
  return (
    <S.PageLayout className={className}>
      {layoutStaticData.histories && (
        <History
          histories={layoutStaticData.histories}
          historyPath={HISTORY_PATH}
        />
      )}
      <S.Header>
        <S.Heading>{layoutStaticData.heading}</S.Heading>
        {button && button}
      </S.Header>
      {children}
    </S.PageLayout>
  );
};

export default PageLayout;
