import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import type { UseMutateFunction } from "@tanstack/react-query";

import { useModal, useToast } from "@/hooks";
import {
  checkPasswordLength,
  checkPasswordType,
  makeCryptoPassword,
} from "@/utils";
import { COMMON_TOAST_MSG, COMMON_ERROR_MSG } from "@/constants";
import type { ApiError, ChangeAccountPasswordQueryModel } from "@/types";

interface Form {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const schema = yup.object().shape({
  currentPassword: yup.string().required(COMMON_ERROR_MSG.FIELD),
  newPassword: yup
    .string()
    .required()
    .notOneOf(
      [yup.ref("currentPassword"), null],
      COMMON_ERROR_MSG.CANNOT_UPDATE_PASSWORD,
    )
    .test("hasTypeError", COMMON_ERROR_MSG.PASSWORD_TYPE, checkPasswordType)
    .test(
      "hasValidLength",
      COMMON_ERROR_MSG.PASSWORD_LENGTH,
      checkPasswordLength,
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword")], COMMON_ERROR_MSG.PASSWORD_CONFIRM)
    .required(COMMON_ERROR_MSG.PASSWORD_CONFIRM),
});

interface UseChangePasswordProps {
  changeAccountPasswordMutate: UseMutateFunction<
    unknown,
    ApiError,
    ChangeAccountPasswordQueryModel,
    unknown
  >;
}

const initForm = { currentPassword: "", newPassword: "", confirmPassword: "" };

const useChangePassword = ({
  changeAccountPasswordMutate,
}: UseChangePasswordProps) => {
  const {
    register,
    watch,
    formState: { errors, touchedFields },
    setError,
    clearErrors,
    handleSubmit,
  } = useForm<Form>({
    defaultValues: initForm,
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const { handleModalClose } = useModal();
  const { addToast } = useToast();

  const handlePasswordChange = handleSubmit(
    ({ currentPassword, newPassword }) => {
      const req = {
        body: {
          currentPassword: makeCryptoPassword(currentPassword),
          newPassword: makeCryptoPassword(newPassword),
        },
      };

      changeAccountPasswordMutate(req, {
        onSuccess: () => {
          addToast(COMMON_TOAST_MSG.SUCCESS.PASSWORD_CHANGE);
          handleModalClose();
        },
        onError: (err) => {
          if (err.response?.data.message === "CANNOT_UPDATE_PASSWORD") {
            setError("currentPassword", {
              type: "validate",
              message: COMMON_ERROR_MSG.CANNOT_UPDATE_PASSWORD,
            });
          }

          if (err.response?.data.message === "INVALID_PASSWORD") {
            setError("currentPassword", {
              type: "validate",
              message: COMMON_ERROR_MSG.CANNOT_UPDATE_PASSWORD,
            });
          }
        },
      });
    },
  );

  return {
    register,
    errors,
    touchedFields,
    setError,
    clearErrors,
    watch,
    handlePasswordChange,
  };
};

export default useChangePassword;
