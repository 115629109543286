import React, { createContext, useContext } from "react";
import { useTranslation } from "react-i18next";

import type { Languages } from "@/types";

import * as S from "./LabelContentTable.styled";

export type LabelContentType = "bg" | "underline" | "empty";

interface LabelContentTableProps {
  children: React.ReactNode;
  className?: string;
  variant: LabelContentType;
  marginBottom?: number;
  subject?: Languages | React.ReactNode;
}

const Context = createContext<{ variant?: LabelContentType }>({});

const LabelContentTable = ({
  children,
  className,
  marginBottom,
  subject,
  variant,
}: LabelContentTableProps) => {
  const { t } = useTranslation();

  return (
    <Context.Provider value={{ variant }}>
      <S.LabelContentTableWrapper
        className={className}
        marginBottom={marginBottom}
      >
        {subject &&
          (typeof subject === "string" ? (
            <S.SubjectWrapper>{t(subject as Languages)}</S.SubjectWrapper>
          ) : (
            <S.SubjectComponentWrapper>{subject}</S.SubjectComponentWrapper>
          ))}
        {children}
      </S.LabelContentTableWrapper>
    </Context.Provider>
  );
};

interface RowProps {
  className?: string;
  partition?: number;
  hasError?: boolean;
  marginTop?: number;
  children: React.ReactNode;
  draggable?: boolean;
  handleDragStart?: () => void;
  handleDragEnd?: () => void;
  handleDragEnter?: () => void;
  handleMouseDown?: () => void;
  handleMouseUp?: () => void;
  handleDragOver?: (e: React.DragEvent<HTMLElement>) => void;
  handleDrop?: (e: React.DragEvent<HTMLElement>) => void;
}

LabelContentTable.Row = function Row({
  className,
  children,
  draggable,
  hasError,
  partition = 1,
  marginTop,
  handleDragStart,
  handleDragEnd,
  handleDragEnter,
  handleMouseDown,
  handleMouseUp,
  handleDragOver,
  handleDrop,
}: RowProps) {
  const { variant } = useContext(Context);

  return (
    <S.Row
      className={className}
      variant={variant}
      marginTop={marginTop}
      hasError={hasError}
      partition={partition}
      draggable={draggable}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragEnter={handleDragEnter}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {children}
    </S.Row>
  );
};

interface ContentProps {
  className?: string;
  label: Languages;
  labelWidth?: number;
  isRequired?: boolean;
  hasError?: boolean;
  tooltip?: React.ReactNode;
  children: React.ReactNode;
}

LabelContentTable.Content = function Content({
  className,
  label,
  labelWidth,
  isRequired,
  hasError,
  tooltip,
  children,
}: ContentProps) {
  const { t } = useTranslation();

  const { variant } = useContext(Context);

  return (
    <S.ContentWrapper
      className={className}
      variant={variant}
      labelWidth={labelWidth}
    >
      <S.NameWrapper>
        <S.Name variant={variant} hasError={hasError}>
          {t(label)}
          {isRequired && <S.Required>*</S.Required>}
          {tooltip && <S.TooltipWrapper>{tooltip}</S.TooltipWrapper>}
        </S.Name>
      </S.NameWrapper>
      <S.Content variant={variant}>{children}</S.Content>
    </S.ContentWrapper>
  );
};

export default LabelContentTable;
