/** @jsxImportSource @emotion/react */ // NOTE: 공용 컴포넌트에서 className으로 수정하려고 하면 필요
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { BaseModal, Button } from "@/components";
import { useModal } from "@/hooks";
import type { Languages } from "@/types";

import * as S from "./DetailModal.styled";

interface DetailModalProps {
  className?: string;
  children: React.ReactNode;
  posFnType?: "submit" | "button";
  isButtonFloat?: boolean;
  isPosLoading?: boolean;
  isPosDisabled?: boolean;
  closeButtonName?: Languages;
  negButtonVariant?: "third" | "error";
  negButtonName?: Languages;
  posButtonName?: Languages;
  gotoNextLink?: string;
  desc?: Languages;
  path?: string;
  title: Languages;
  closeFn?: () => void;
  linkFn?: () => void;
  negFn?: (e: React.MouseEvent) => void;
  posFn?: () => void;
}

const DetailModal = React.forwardRef<HTMLDialogElement, DetailModalProps>(
  (
    {
      className,
      children,
      isButtonFloat,
      isPosLoading,
      isPosDisabled,
      closeButtonName = "Close",
      negButtonVariant = "error",
      negButtonName,
      posButtonName,
      gotoNextLink,
      path,
      title,
      desc,
      posFnType = "button",
      closeFn,
      negFn,
      posFn,
      linkFn,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const { handleModalClose } = useModal();

    return (
      <BaseModal css={S.baseModal} className={className} ref={ref}>
        <S.DetailHeader>
          <S.Title>{t(title)}</S.Title>
          {desc && <S.DetailDesc>{t(desc)}</S.DetailDesc>}
        </S.DetailHeader>
        <S.DetailContent>{children}</S.DetailContent>
        <S.DetailInfoFooter isButtonFloat={isButtonFloat}>
          {closeButtonName && (
            <Button
              variant="secondary"
              label={closeButtonName}
              handleButtonClick={closeFn ?? handleModalClose}
            />
          )}
          <div>
            {negButtonName && (
              <Button
                variant={negButtonVariant}
                label={negButtonName}
                handleButtonClick={negFn}
              />
            )}
            {posButtonName && (
              <Button
                isLoading={isPosLoading}
                isDisabled={isPosDisabled}
                type={posFnType}
                variant="primary"
                label={posButtonName}
                handleButtonClick={posFn && posFn}
              />
            )}
            {gotoNextLink && (
              <Link
                css={S.detailInfoGoNextLink}
                to={path as string}
                onClick={linkFn}
              >
                {t(gotoNextLink as Languages)}
              </Link>
            )}
          </div>
        </S.DetailInfoFooter>
      </BaseModal>
    );
  },
);

DetailModal.displayName = "DetailModal";

export default DetailModal;
