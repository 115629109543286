/* eslint-disable no-useless-escape */

export const checkEmailValidation = (email: string): boolean => {
  const checkEmail =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@([\w-])+(\.[a-zA-Z]{2,4}){1,2}$/;
  // 64, 63: 백에서 쓰는 joi 라이버러리의 최대 값

  const [id, domain] = email.split(/[.@]/);

  if (id?.length >= 64 || domain?.length >= 63) return false;

  return checkEmail.test(email);
};

export const checkPasswordLength = (v: string): boolean => {
  const minCheck = v.length >= 8;
  const maxCheck = v.length <= 20;

  return minCheck && maxCheck;
};

export const checkPasswordType = (value: string): boolean => {
  const checkNumber = /(?=.*[0-9])/; // NOTE: 숫자 체크
  const checkBigLetter = /(?=.*[A-Z])/; // NOTE: 대문자 체크
  const checkSmallLetter = /(?=.*[a-z])/; // NOTE: 소문자 체크
  const checkSpecial = /(?=.*[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/])/; // NOTE: 특수 문자 체크

  const typeChecker = {
    isNumber: false,
    isBigLetter: false,
    isSmallLetter: false,
    isSpecial: false,
  };

  typeChecker.isNumber = checkNumber.test(value);
  typeChecker.isBigLetter = checkBigLetter.test(value);
  typeChecker.isSmallLetter = checkSmallLetter.test(value);
  typeChecker.isSpecial = checkSpecial.test(value);

  return Object.values(typeChecker).filter((item) => item).length > 1;
};
