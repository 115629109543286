/** @jsxImportSource @emotion/react */ // NOTE: 공용 컴포넌트에서 className으로 수정하려고 하면 필요
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { InternalLinkButton } from "@/components";
import { ErrorIcon } from "@/assets";

import * as S from "./NotFound.styled";

interface NotFoundProps {
  resetErrorState?: () => void;
  path: string;
}

const NotFound = ({ resetErrorState, path }: NotFoundProps) => {
  const location = useLocation();

  useEffect(() => {
    return () => {
      resetErrorState && resetErrorState();
    };
  }, [location.pathname]);

  return (
    <S.NotFound>
      <S.Container>
        <ErrorIcon css={S.icon} />
        <S.Title>Page not found</S.Title>
        <S.Desc>
          The requested page could not be found. <br />
          Please check that the address on the page you entered is correct
        </S.Desc>
        <InternalLinkButton variant="secondary" to={path}>
          Go home
        </InternalLinkButton>
      </S.Container>
    </S.NotFound>
  );
};

export default NotFound;
