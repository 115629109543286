import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Profile = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

export const ProfilImg = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 30px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

export const Name = styled.span`
  ${({ theme }) => css`
    ${theme.font.medium_15};

    margin-bottom: 4px;
    color: ${theme.color.black};
  `}
`;

export const ProfileBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    border-radius: 50%;
    padding: 16px;
    background-color: ${theme.color.gray_40};
  `}
`;

export const clientIcon = (theme: Theme) => css`
  & > path {
    fill: ${theme.color.white};
  }
`;
