import React from "react";
import type { AxiosError } from "axios";

import { NotFound } from "@/components";

import { PATH } from "constants/index";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  badRequestError: boolean;
  notFoundError: boolean;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      badRequestError: false,
      notFoundError: false,
    };
  }

  componentDidCatch(error: AxiosError) {
    this.setState({ hasError: true });

    if (error.name === "AxiosError") {
      if (error.response?.status === 400) {
        this.setState({ badRequestError: true });
      } else if (error.response?.status === 404) {
        this.setState({ notFoundError: true });
      }
    }
  }

  resetErrorState() {
    this.setState({
      hasError: false,
      badRequestError: false,
      notFoundError: false,
    });
  }

  render() {
    if (!this.state.hasError) return this.props.children;

    if (this.state.notFoundError) {
      return (
        <NotFound resetErrorState={this.resetErrorState} path={PATH.ROOT} />
      );
    }

    if (this.state.badRequestError) {
      return <div>Bad request</div>;
    }

    return this.props.children;
  }
}
