import React, {
  useMemo,
  useState,
  createContext,
  useRef,
  useContext,
  useEffect,
} from "react";

export const GoogleMapContext = createContext<{
  googleMap: google.maps.Map | null;
  setGoogleMap: ((map: google.maps.Map | null) => void) | null;
}>({
  googleMap: null,
  setGoogleMap: null,
});

export const useGoogleMap = () => {
  const ref = useRef(null);

  const { googleMap, setGoogleMap } = useContext(GoogleMapContext);

  useEffect(() => {
    if (ref.current && setGoogleMap) {
      const map = new google.maps.Map(ref.current, {
        streetViewControl: false,
        mapTypeId: "hybrid",
        mapTypeControl: false,
      });

      setGoogleMap(map);

      return () => {
        setGoogleMap(null);
      };
    }
  }, []);

  return { ref, googleMap };
};

export const GoogleMapProvider = ({ children }: { children: JSX.Element }) => {
  const [googleMap, setGoogleMap] = useState<google.maps.Map | null>(null);

  const value = useMemo(() => ({ googleMap, setGoogleMap }), [googleMap]);

  return (
    <GoogleMapContext.Provider value={value}>
      {children}
    </GoogleMapContext.Provider>
  );
};
