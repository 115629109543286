import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const LabelInputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 100%;
  height: 52px;
`;

export const Label = styled.label<{ isLabelTop: boolean }>`
  ${({ theme, isLabelTop }) => css`
    ${isLabelTop ? theme.font.regular_12 : theme.font.regular_15};
    position: absolute;
    top: ${isLabelTop ? 0 : "16px"};
    color: ${theme.color.gray_50};
    transition-duration: 0.3s;
  `}
`;

export const Input = styled.input<{ hasErr: boolean }>`
  ${({ theme, hasErr }) => css`
    ${theme.font.regular_15};
    height: 37px;
    outline: 0;
    border: 0;
    border-bottom: 1px solid
      ${hasErr ? theme.color.red_20 : theme.color.gray_30};
    background-color: inherit;

    &:focus {
      border: 0;
      border-bottom: 1px solid ${theme.color.blue_10};
    }
  `}
`;

export const password = css`
  padding-right: calc(24px + 16px);
`;

export const PasswordShowButton = styled.button`
  position: absolute;
  bottom: 12px;
  right: 0px;
  width: 24px;
  height: 24px;
`;

export const eyeIcon = (isShow: boolean) => (theme: Theme) =>
  css`
    & > path {
      fill: ${isShow ? theme.color.blue_10 : theme.color.gray_40};
    }
  `;
