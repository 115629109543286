import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const TextareaWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 13px 20px 37px;
`;

export const Textarea = styled.textarea`
  ${({ theme }) => css`
    ${theme.scrollbar};
    width: 100%;
    height: 100%;
    border: 0;
    color: ${theme.color.black};
    resize: none;

    &::placeholder {
      ${theme.font.regular_14};
      color: ${theme.color.gray_40};
    }

    &:disabled {
      color: inherit;
      background-color: inherit;
    }
  `}
`;

export const Length = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    position: absolute;
    bottom: 13px;
    left: 20px;
    color: ${theme.color.gray_50};
    z-index: 11;
  `}
`;
