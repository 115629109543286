import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useScript from "react-script-hook";

import { Header, Navbar } from "@/components";

import { useAuth } from "contexts";
import { useLogout } from "services";
import { LogoIcon, NAVS } from "assets";
import { PATH } from "constants/index";
import * as S from "./PrivateLayout.styled";

interface PrivateLayoutProps {
  className?: string;
}

const PrivateLayout = ({ className }: PrivateLayoutProps) => {
  const navigate = useNavigate();

  const [loading] = useScript({
    src: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLEMAP_KEY}&libraries=places&language=en`, // NOTE: 언어 번역이 없어, en 으로 고정했습니다.
  });

  const { auth } = useAuth();

  const { mutate: logoutMutate } = useLogout();

  const handleLogout = (): void => {
    logoutMutate(undefined, {
      onSuccess: () => {
        auth.signOut();
        navigate(PATH.ROOT);
      },
    });
  };

  if (loading) {
    return <></>;
  }

  return (
    <>
      <Header icon={<LogoIcon />} handleLogout={handleLogout} />
      <Navbar navs={NAVS} />
      <S.Main className={className}>
        <Outlet />
      </S.Main>
    </>
  );
};

export default PrivateLayout;
