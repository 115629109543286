/** @jsxImportSource @emotion/react */ // NOTE: 공용 컴포넌트에서 className으로 수정하려고 하면 필요
import React, { createContext, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Tooltip } from "@/components";
import { SearchDataIcon } from "@/assets";
import type { Languages } from "@/types";

import * as S from "./LabelFieldTable.styled";

export type DataSelection = {
  isSelected: boolean;
  emptyHeight: number;
};

interface LabelFieldTableProps {
  className?: string;
  children: React.ReactNode;
  caption: Languages;
  dataSelection: DataSelection;
}

interface LabelFieldTableRowProps {
  children?: React.ReactNode;
  className?: string;
  title: Languages;
  desc?: string; // NOTE: 대부분 서버데이터라 Languages가 아닌 string type으로 진행
  tip?: Languages;
}

interface LabelFieldTableBottomProps {
  className?: string;
  children: React.ReactNode;
}

interface LabelFieldTableContextValue {
  dataSelection: DataSelection;
}

const LabelFieldTableContext = createContext<LabelFieldTableContextValue>({
  dataSelection: {
    isSelected: false,
    emptyHeight: 146,
  },
});

const LabelFieldTable = ({
  className,
  children,
  dataSelection,
  caption,
}: LabelFieldTableProps) => {
  const { t } = useTranslation();

  return (
    <LabelFieldTableContext.Provider value={{ dataSelection }}>
      <S.LabelFieldTable className={className}>
        <S.Caption>{t(caption)}</S.Caption>
        <S.Tbody>
          {dataSelection.isSelected ? (
            children
          ) : (
            <S.EmptyWrapper height={dataSelection.emptyHeight}>
              <td>
                <SearchDataIcon css={S.searchDataIcon} />
              </td>
            </S.EmptyWrapper>
          )}
        </S.Tbody>
      </S.LabelFieldTable>
    </LabelFieldTableContext.Provider>
  );
};

LabelFieldTable.Row = function Row({
  children,
  className,
  title,
  desc,
  tip,
}: LabelFieldTableRowProps) {
  const { t } = useTranslation();

  const { dataSelection } = useContext(LabelFieldTableContext);

  return (
    <S.Row className={className}>
      <th scope="row">{t(title)}</th>
      <td>
        {dataSelection.isSelected ? desc : ""}
        {tip && <Tooltip css={S.tip} position="BOTTOM_RIGHT" message={tip} />}
        {children && children}
      </td>
    </S.Row>
  );
};

LabelFieldTable.Bottom = function Bottom({
  className,
  children,
}: LabelFieldTableBottomProps) {
  return (
    <S.Bottom className={className}>
      <td>{children}</td>
    </S.Bottom>
  );
};

export default LabelFieldTable;
