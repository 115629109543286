import React from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { InternalLinkButton, NoResult } from "@/components";
import { useFilterParams } from "@/hooks";
import { isInitFilterAtom } from "@/stores";
import { MapIcon, ResetIcon } from "@/assets";
import { formatLocalDateTime, formatUnixToLocalDateTime } from "@/utils";

import {
  DEVICE_DETAIL_FILTER_QUERIES,
  DEVICE_DETAIL_TABLE_HEADER,
} from "assets";
import type { GetIoTDeviceDetailServerModel, GetIoTDeviceFilter } from "types";
import { useDeviceDetail, useDeviceDetailFilter } from "./hooks";
import * as S from "./DeviceDetailSection.styled";

const DeviceDetailSection = () => {
  const { deviceId } = useParams();

  const isInitFilter = useRecoilValue(isInitFilterAtom);

  const { filters } = useFilterParams<GetIoTDeviceFilter>(
    DEVICE_DETAIL_FILTER_QUERIES,
    undefined,
    true,
  );

  const req = { id: deviceId!, query: { date: filters.startDate } };

  useDeviceDetailFilter(req);
  const { observerRef, data, dataUpdatedAt, refetch } = useDeviceDetail(
    req,
    !!filters.startDate && !!filters.endDate,
  );

  const formatTableData = (
    key: keyof typeof DEVICE_DETAIL_TABLE_HEADER,
    item: GetIoTDeviceDetailServerModel["thingInfo"][number],
  ) => {
    switch (key) {
      // NOTE: Unix Timestamp (seconds)
      case "eventTime":
        return item[key]
          ? formatUnixToLocalDateTime(+item[key], "DD/MM/YYYY HH:mm")
          : "-";

      case "connection":
        return item[key] === true ? "true" : "false";

      default:
        return item[key] || "-";
    }
  };

  return (
    <>
      <S.ListHeader>
        <S.LastUpdatedWrapper>
          <S.LastUpdated>Latest updated:</S.LastUpdated>
          <S.LastUpdatedAt>
            {dataUpdatedAt
              ? formatLocalDateTime(dataUpdatedAt, "DD/MM/YYYY, HH:mm")
              : "-"}
          </S.LastUpdatedAt>
          <S.UpdateButton type="button" onClick={() => refetch()}>
            <ResetIcon css={S.resetIcon} />
          </S.UpdateButton>
        </S.LastUpdatedWrapper>
        <InternalLinkButton
          css={S.mapButton}
          variant="third"
          to={`/device/${deviceId}/map`}
        >
          <MapIcon />
        </InternalLinkButton>
      </S.ListHeader>
      <S.ListWrapper>
        <S.ListItemHeader>
          {Object.entries(DEVICE_DETAIL_TABLE_HEADER).map(([key, label]) => (
            <S.ListItemHeaderItem key={key} title={label}>
              {label}
            </S.ListItemHeaderItem>
          ))}
        </S.ListItemHeader>
        <S.ListItemWrapper>
          {isInitFilter ? (
            <NoResult
              css={S.noResult}
              type="select"
              contents={["Please apply the filter to search"]}
            />
          ) : !data?.length ? (
            <NoResult
              css={S.noResult}
              type="search"
              contents={["No results found"]}
            />
          ) : (
            data.map((item, i) => (
              <S.ListItem
                key={i}
                ref={data?.length - 1 === i ? observerRef : null}
              >
                {Object.entries(DEVICE_DETAIL_TABLE_HEADER).map(
                  ([key, label]) => {
                    return (
                      <S.Item key={key} title={label}>
                        {formatTableData(
                          key as keyof typeof DEVICE_DETAIL_TABLE_HEADER,
                          item,
                        )}
                      </S.Item>
                    );
                  },
                )}
              </S.ListItem>
            ))
          )}
        </S.ListItemWrapper>
      </S.ListWrapper>
    </>
  );
};

export default DeviceDetailSection;
