import Cookies from "js-cookie";

import { Auth } from "@/utils";

import type { LoginServerModel } from "types";

export class IoTAuth extends Auth<LoginServerModel> {
  private static instance: IoTAuth;

  constructor() {
    super("iot");
  }

  public static getInstance(): IoTAuth {
    if (!IoTAuth.instance) {
      IoTAuth.instance = new IoTAuth();
    }
    return IoTAuth.instance;
  }

  protected setUser(user: LoginServerModel): void {
    this.user = user;
    Cookies.set(this.key, JSON.stringify(user));
  }

  protected onUserChange(user: LoginServerModel | null, error?: any): void {
    if (this.cb) {
      this.cb(user, error);
    }
  }

  public onAuthStateChanged(
    cb: (user: LoginServerModel | null, error: any) => void,
  ): void {
    this.cb = cb;
    this.onUserChange(this.user, null);
  }

  public signIn(data: LoginServerModel): void {
    this.setUser(data);
    this.onUserChange(this.user, null);
  }

  public signOut(): void {
    super.signOut();
  }

  public changeAccessToken(accessToken: string): void {
    if (!this.user) {
      throw new Error("changeAccessToken : user empty");
    }

    const updatedUser = {
      ...this.user,
      accessToken,
    };

    this.setUser(updatedUser);
    Cookies.set(this.key, JSON.stringify(updatedUser));
  }
}
