import CryptoJS from "crypto-js";

// NOTE: sha256으로 비밀번호 만들고, hex 형식으로 string 만듬
export const makeCryptoPassword = (password: string): string =>
  CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);

export const makeHmacApiHeader = (): {
  "x-api-data": string;
  "x-api-signature": string;
} => {
  if (!process.env.REACT_APP_HMAC_KEY) {
    throw new Error("REACT_APP_HMAC_KEY is not defined");
  }

  const data = `${Date.now()}`;
  const signature = CryptoJS.HmacSHA256(
    data,
    process.env.REACT_APP_HMAC_KEY,
  ).toString(CryptoJS.enc.Hex);

  return { "x-api-data": data, "x-api-signature": signature };
};
