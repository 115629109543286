import { ZERO_KIP } from "@/assets";

export const comma = (str: string | number): string => {
  if (typeof str !== "string") str = `${str}`;

  if (!str) return "";

  return `${str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,")}`;
};

export const commaWithUnit = (
  str: string | number,
  unit: "km" | "m" | "kg",
): string => {
  if (typeof str !== "string") str = `${str}`;

  if (!str) return "";

  return `${comma(str)} ${unit || ""}`;
};

interface CommaWithCurrencyUnitProps {
  price: string | number | null | undefined;
  currencyUnit: string;
  showPlusSign: boolean;
}

export const commaWithCurrencyUnit = ({
  price,
  currencyUnit,
  showPlusSign,
}: CommaWithCurrencyUnitProps): string => {
  if (typeof price === "number") price = `${price}`;

  if (!price) return ZERO_KIP;

  if (+price < 0) {
    return `- ${currencyUnit} ${comma(Math.abs(+price))}`;
  } else {
    return showPlusSign
      ? `+ ${currencyUnit} ${comma(+price)}`
      : `${currencyUnit} ${comma(+price)}`;
  }
};
