import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ErrorIcon } from "@/assets";
import { useToast } from "@/hooks";
import { SuccessIcon } from "@/assets";
import type { Toast } from "@/types";

import * as S from "./ToastItem.styled";

interface ToastItemProps extends Toast {
  id: string;
}

const TRANSITION_DURATION = 1000;
const TOAST_DURATION = 3000;

const ToastItem = ({ type, id, content }: ToastItemProps) => {
  const { t } = useTranslation();

  const [isClosing, setIsClosing] = useState(false);
  const { removeToast } = useToast();

  useEffect(() => {
    const existTimeout = setTimeout(() => {
      setIsClosing(true);
    }, TOAST_DURATION);

    const expireToastTimeout = setTimeout(() => {
      removeToast(id);
    }, TOAST_DURATION + TRANSITION_DURATION);

    return () => {
      clearTimeout(existTimeout);
      clearTimeout(expireToastTimeout);
    };
  }, []);

  return (
    <S.ToastItem isClosing={isClosing}>
      <S.Item data-type={type} toastType={type}>
        <div>{type === "success" ? <SuccessIcon /> : <ErrorIcon />}</div>
        <p>{t(content)}</p>
      </S.Item>
    </S.ToastItem>
  );
};

export default ToastItem;
