/** @jsxImportSource @emotion/react */ // NOTE: 공용 컴포넌트에서 className으로 수정하려고 하면 필요
import React from "react";
import { useTranslation } from "react-i18next";

import { TooltipIcon } from "@/assets";
import type { Languages, TooltipPosition } from "@/types";

import * as S from "./Tooltip.styled";

interface TooltipProps {
  className?: string;
  position: TooltipPosition;
  message: Languages;
}

const Tooltip = ({ className, position, message }: TooltipProps) => {
  const { t } = useTranslation();

  return (
    <S.Tooltip className={className} aria-describedby="tip">
      <TooltipIcon css={S.tooltipIcon} />
      <S.Container position={position}>
        <S.Message id="tip" role="tooltip">
          {t(message)}
        </S.Message>
      </S.Container>
    </S.Tooltip>
  );
};

export default Tooltip;
