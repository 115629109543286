import React, { ReactNode } from "react";

import * as S from "./PublicLayout.styled";

interface PublicLayoutProps {
  children: ReactNode;
}

const PublicLayout = ({ children }: PublicLayoutProps) => {
  return <S.Root>{children}</S.Root>;
};

export default PublicLayout;
