/** @jsxImportSource @emotion/react */ // NOTE: 공용 컴포넌트에서 className으로 수정하려고 하면 필요
import React from "react";

import { GhostButton } from "@/components";
import { TranslateIcon } from "@/assets";

import * as S from "./Header.styled";

interface HeaderProps {
  icon: React.ReactNode;
  handleLogout: () => void;
  rank?: React.ReactNode;
  dropdown?: React.ReactNode;
}

const Header = ({ icon, rank, dropdown, handleLogout }: HeaderProps) => {
  return (
    <S.Header>
      {icon}
      <S.ControlWrapper>
        {rank && rank}
        {dropdown && (
          <S.LanguageWrapper>
            <TranslateIcon css={S.translateIcon} />
            {dropdown}
          </S.LanguageWrapper>
        )}
        <GhostButton
          css={S.logoutButton}
          variant="ghost"
          label="Sign out"
          handleButtonClick={handleLogout}
        />
      </S.ControlWrapper>
    </S.Header>
  );
};

export default Header;
